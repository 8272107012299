import {
  Chip,
  Link,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { PATHS } from 'routes';
import { getStatusByName, STATUS } from 'assets/constants/statuses';
import { CheckCircleOutline, CrossCircleIcon, OpenEyeIcon } from 'assets/icons';
import {
  useAuth,
  useDate,
  useLocale,
  useModal,
  usePermission,
} from 'util/hooks';
import PERMISSIONS from 'assets/constants/permissions';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { DownloadButton } from 'components/molecules';

const useTableColumns = (props) => {
  const { fetchAnnouncements } = props;

  const { format } = useDate();
  const { t, dir } = useLocale();
  const { addModal } = useModal();

  const { currentUser, isAdmin, isBusinessAdmin } = useAuth();
  const { username } = currentUser;

  const canPublish = usePermission(PERMISSIONS.announcementPublish);
  const canExpire = usePermission(PERMISSIONS.announcementExpire);
  const canDeleteAll = isAdmin || isBusinessAdmin;

  const columns = [
    {
      field: 'title',
      headerName: t('common.title'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'startAt',
      headerName: t('announcements.startDateAndTime'),
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => format(row.startAt, 'PPpp'),
    },
    {
      field: 'endAt',
      headerName: t('announcements.endDateAndTime'),
      flex: 1,
      minWidth: 200,
      valueGetter: ({ row }) => format(row.endAt, 'PPpp'),
    },
    {
      field: 'image',
      headerName: t('common.image'),
      flex: 1,
      minWidth: 150,
      renderCell: (params) => (
        <DownloadButton
          src={params.row.image}
        />
      ),
    },
    {
      field: 'createdBy',
      headerName: t('common.createdBy'),
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => (
        <Chip
          size="small"
          color={params.row.status || ''}
          label={t(getStatusByName(params?.row?.status)?.localizationKey) || ''}
        />
      ),
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: ({ row }) => {
        const { status, id, createdBy } = row;

        const isDraft = status === STATUS.draft;
        const showPublishButton = canPublish && isDraft;
        const isActive = status === STATUS.active;
        const showExpireButton = canExpire && isActive;
        const isOwnAnnouncement = createdBy === username;
        const showDeleteButton = isDraft && (canDeleteAll || isOwnAnnouncement);

        const actions = [
          <GridActionsCellItem
            component={Link}
            dir={dir}
            icon={<OpenEyeIcon width="20" height="20" />}
            label={t('common.view')}
            to={`/${PATHS.announcements}/${id}`}
            sx={{ minWidth: 180 }}
            showInMenu
          />,
        ];

        const addModalToActions = (type, icon) => {
          actions.push(
            <GridActionsCellItem
              dir={dir}
              icon={icon}
              label={t(`common.${type}`)}
              onClick={() => addModal({
                key: MODAL_KEYS.dashboardAnnouncement,
                props: {
                  type,
                  refetch: fetchAnnouncements,
                  ...row,
                },
              })}
              sx={{ minWidth: 180 }}
              showInMenu
            />,
          );
        };

        if (showPublishButton) {
          addModalToActions(
            'publish',
            (<CheckCircleOutline
              width="20"
              height="20"
            />),
          );
        }
        if (showDeleteButton) {
          addModalToActions(
            'delete',
            (<CrossCircleIcon
              width="20"
              height="20"
            />),
          );
        }
        if (showExpireButton) {
          addModalToActions(
            'expire',
            (<CrossCircleIcon
              width="20"
              height="20"
            />),
          );
        }
        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
