import _ from 'lodash';
import { useParams } from 'react-router-dom';

import {
  Box,
  Card,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';

import { TypographyWithIcon, UserAwareAvatar } from 'components/molecules';

import {
  useAuth,
  useDate,
  useLocale,
  useIsEvent,
} from 'util/hooks';
import {
  FlagIcon,
  SparklingIcon,
  BloodDropIcon,
  FlagWithHashIcon,
  BloodDropWithHashIcon,
} from 'assets/icons';

import { PATHS } from 'routes';
import { capitalizeFirstLetter } from 'util/helpers';
import { STATUS } from 'assets/constants/statuses';
import SubmissionActions from './SubmissionActions';

const PlayerActivityCard = (props) => {
  const {
    activity = {},
    showPoints = false,
    eventHasStarted = false,
  } = props;

  const { t } = useLocale();
  const isEvent = useIsEvent();
  const { fromNow } = useDate();
  const { teamId } = useParams();
  const { eventName } = useParams();
  const { currentUser: { isFreePlanUser = false } } = useAuth();

  const {
    id = '',
    isChallenge,
    player = {},
    challenge = {},
    submittedAt = '',
    pointsEarned = '',
    firstBlood = false,
    activityDetails = {},
    isRootSubmission = false,
    entityStatus: status = '',
    entityVisibility: visible = false,
    machineSubmissionType = '',
  } = activity || {};

  const {
    lab = {},
    id: activityId = '',
    points: corePoints = '',
    name: activityName = '',
  } = activityDetails || {};

  const {
    id: challengeId = '',
    name: challengeName = '',
    points: challengePoints = '',
  } = challenge || {};

  const {
    avatar = '',
    username = '',
  } = player || {};

  const showPlayerInfo = !_.isEmpty(player);
  const showActions = !!teamId && eventHasStarted;
  const originalPoints = isEvent ? challengePoints : corePoints;
  const nonClickableActivity = isFreePlanUser
  && (status === STATUS.archived
  || visible === false);
  const tooltipDesc = isChallenge ? t('challenges.nonAvailable')
    : t('machines.nonAvailable');

  const modalProps = {
    username,
    challengeId,
    challengeName,
    submissionId: id,
  };

  // Submission date
  const submittedAtString = fromNow(submittedAt);

  // Icon
  const iconSize = { width: 21, height: 21 };
  const getIcon = () => {
    // 1. challenge flag => normal flag icon
    // 2. challenge first blood => normal drop icon
    // 3. machine user flag => normal flag icon
    // 4. machine user blood => normal drop icon
    // 5. machine root flag => flag with # icon
    // 6. machine root blood => drop with # icon

    if (isRootSubmission) {
      return firstBlood
        ? <BloodDropWithHashIcon {...iconSize} />
        : <FlagWithHashIcon {...iconSize} />;
    }

    return firstBlood
      ? <BloodDropIcon {...iconSize} />
      : <FlagIcon {...iconSize} />;
  };

  // Avatar
  const avatarSize = {
    width: { xs: 18, sm: 36 },
    height: { xs: 18, sm: 36 },
  };
  const userAvatar = (
    <UserAwareAvatar
      src={avatar}
      alt={capitalizeFirstLetter(username)}
      user={player}
      sx={{
        ...avatarSize,
        fontWeight: 'bold',
        fontSize: 18,
      }}
    />
  );

  // Url
  const getActivityUrl = () => {
    if (isChallenge) {
      const {
        id: labId,
        type: labType,
      } = lab;

      return `/${PATHS.labs}/${labType}-labs/${labId}/${PATHS.challenges}/${activityId}`;
    }

    if (eventName) {
      return `/${PATHS.events}/${eventName}/${PATHS.challenges}/${challengeId}`;
    }

    return `/${PATHS.machines}/${activityName}`;
  };

  // Activity
  const getEarnedFlag = () => {
    if (isChallenge || eventName) {
      return firstBlood
        ? t('challenges.firstBlood')
        : t('common.theFlag');
    }

    return firstBlood
      ? t(`machines.${machineSubmissionType}Blood`)
      : t(`machines.${machineSubmissionType}Flag`);
  };

  const activityNameWrapper = nonClickableActivity ? 'div' : Link;
  const activityUrl = nonClickableActivity ? undefined : getActivityUrl();

  const activityTitle = (
    <Stack
      direction="row"
      sx={{
        alignItems: 'center',
        flexWrap: { xs: 'wrap', sm: 'nowrap' },
      }}
    >
      {showPlayerInfo && (
        <Box sx={{ mr: 2 }}>
          <Link to={`/profile/${username}`}>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
              <Box sx={{
                ...avatarSize,
                mr: 2,
              }}
              >
                {userAvatar}
              </Box>
              <Typography
                variant="bodyStandardMedium"
                color="text.primary"
              >
                {username}
              </Typography>
            </Box>
          </Link>
        </Box>
      )}
      <Typography
        noWrap
        variant="bodyStandardRegular"
        color="text.secondary"
        sx={{
          display: 'flex',
          flexWrap: { xs: 'wrap', sm: 'nowrap' },
        }}
      >
        {t('profile.claimed')}
      &nbsp;
        {getEarnedFlag().toLowerCase()}
      &nbsp;
        {t('common.in')}
      &nbsp;
        <Tooltip
          title={tooltipDesc}
          placement="bottom"
          componentsProps={{
            tooltip: {
              sx: {
                display: nonClickableActivity ? 'block' : 'none',
              },
            },
          }}
        >
          <Box
            component={activityNameWrapper}
            to={activityUrl}
          >
            <Typography
              variant="bodyStandardMedium"
              color="text.primary"
            >
              {activityName || challengeName}
            </Typography>
          </Box>
        </Tooltip>
      </Typography>
    </Stack>
  );

  // First blood Bonus
  const firstBloodBonus = !isEvent && firstBlood
    ? (pointsEarned - originalPoints)
    : null;

  // Points
  const points = (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <TypographyWithIcon
        label={`${originalPoints || 0} ${t('common.points').toLowerCase()}`}
        labelProps={{ variant: 'BodyStandardMedium' }}
        icon={(
          <SparklingIcon
            width="19"
            height="19"
          />
        )}
        iconProps={{
          mr: 2,
        }}
      />
      {firstBloodBonus && (
        <Box ml={1}>
          <Typography
            variant="bodySmallMedium"
            color="text.red"
          >
            {`+${firstBloodBonus} ${t('common.points').toLowerCase()}`}
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Card sx={{
      px: 6,
      py: 4.5,
    }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: 1,
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          width: {
            xs: 1,
            sm: 0.85,
          },
          flex: showPoints ? 2 : 'auto',
        }}
        >
          <Box sx={{
            ...iconSize,
            mr: 5,
          }}
          >
            {getIcon()}
          </Box>
          <Box>
            {activityTitle}
          </Box>
        </Box>
        {showPoints && (
          <Box
            sx={{
              flex: 1,
              minWidth: 200,
              display: 'flex',
            }}
          >
            {points}
          </Box>
        )}
        <Box
          sx={{
            flex: { xs: 0, sm: 1 },
            textAlign: 'right',
          }}
        >
          <Typography
            variant="bodySmallRegular"
            color="text.lightGray"
            noWrap
          >
            {submittedAtString}
          </Typography>
        </Box>
        {showActions && (
          <Box sx={{
            flex: 1,
            minWidth: 200,
            display: 'flex',
            justifyContent: 'end',
          }}
          >
            <SubmissionActions modalProps={modalProps} />
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default PlayerActivityCard;
