import { memo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Image } from 'mui-image';

import {
  AppBar as MUIAppBar,
  Box,
  Toolbar,
  Typography,
  Menu,
  Container,
  MenuItem,
  Button,
  ListItemIcon,
  CircularProgress,
  Divider,
  Link,
} from '@mui/material';
import {
  KeyboardArrowDown,
} from '@mui/icons-material';

import { UserAwareAvatar } from 'components/molecules';

import { PATHS } from 'routes';
import { useAuth, useLocale } from 'util/hooks';
import { flagyardLogo } from 'assets/images';
import { capitalizeFirstLetter } from 'util/helpers';
import {
  UserIcon,
  SettingsIcon,
  EarthIcon,
  ArrowCircleIcon,
  SubscriptionFileStarIcon,
} from 'assets/icons';

import { MOBILE_BREAKPOINT } from 'assets/constants/breakpoints';
import UpgradeAction from './UpgradeAction';

const AppBar = (props) => {
  const { children, ...rest } = props;

  const navigate = useNavigate();
  const {
    currentUser,
    isPlayer,
    logout,
  } = useAuth();
  const {
    t, i18n, dir, isAr,
  } = useLocale();

  const [anchorElUser, setAnchorElUser] = useState(null);
  const hasSubscriptions = isPlayer;
  const hasProfile = isPlayer;

  const {
    isFreePlanUser = true,
    avatar = '',
    username = '',
  } = currentUser || {};

  const showUpgradeAction = isPlayer && isFreePlanUser;

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const userMenuItems = [
    hasProfile && {
      id: 'profile',
      label: t('common.profile'),
      icon: <UserIcon width="22" height="22" />,
      path: `/profile/${username}`,
      onClick: () => [handleCloseUserMenu(), navigate(`/profile/${username}`)],
    },
    hasSubscriptions && {
      id: 'subscriptions',
      label: t('common.subscriptions'),
      icon: <SubscriptionFileStarIcon width="22" height="22" />,
      path: `/${PATHS.subscriptions}`,
      onClick: () => [handleCloseUserMenu(), navigate(`/${PATHS.subscriptions}`)],
    },
    {
      id: 'settings',
      label: t('common.settings'),
      icon: <SettingsIcon width="22" height="22" />,
      path: `/${PATHS.settings}`,
      onClick: () => [handleCloseUserMenu(), navigate(`/${PATHS.settings}`)],
      dividerAfter: true,
    },
    {
      id: 'toggle-language',
      label: isAr ? 'English' : 'العربية',
      icon: <EarthIcon width="22" height="22" />,
      path: '#',
      onClick: () => {
        i18n.changeLanguage(isAr ? 'en' : 'ar');
        handleCloseUserMenu();
      },
    },
    {
      id: 'logout',
      label: t('common.logout'),
      icon: <ArrowCircleIcon width="22" height="22" />,
      path: '#',
      onClick: logout,
    },
  ]
    .filter((el) => el); // filter empty values

  const renderAccountMenu = () => (
    <Box sx={{ flexGrow: 0 }}>
      <Button
        id="user-menu-button"
        aria-controls={anchorElUser ? 'user-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={anchorElUser ? 'true' : undefined}
        sx={{ p: 0 }}
        disableElevation
        disableRipple
        onClick={handleOpenUserMenu}
        endIcon={<KeyboardArrowDown sx={{ color: 'text.primary' }} />}
      >
        <UserAwareAvatar
          src={avatar}
          alt={capitalizeFirstLetter(username)}
          user={currentUser}
        />
        <Typography variant="bodyStandardStandard" sx={{ mx: 2, color: 'text.primary' }}>
          {username}
        </Typography>
      </Button>
      <Menu
        sx={{ mt: 15 }}
        id="menu-appbar"
        anchorEl={anchorElUser}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorElUser)}
        onClose={handleCloseUserMenu}
      >
        {userMenuItems
          .map((menuItem) => (
            <Box key={menuItem.id} sx={{ width: 167 }}>
              <MenuItem onClick={menuItem.onClick} dir={dir}>
                <ListItemIcon sx={{ width: '22px', height: '22px' }}>
                  {menuItem.icon}
                </ListItemIcon>
                <Typography variant="bodySmallMedium">{menuItem.label}</Typography>
              </MenuItem>
              {menuItem.dividerAfter && <Divider variant="middle" />}
            </Box>
          ))}
      </Menu>
    </Box>
  );

  return (
    <MUIAppBar
      position="static"
      elevation={0}
      {...rest}
    >
      <Container
        disableGutters
        maxWidth={false}
      >
        <Toolbar
          disableGutters
          sx={{
            px: {
              xs: 2,
              sm: 4,
              md: 12,
            },
          }}
        >
          {children}
          <Typography
            noWrap
            component="div"
            sx={{ flexGrow: 1, display: 'flex' }}
          >
            <Link to={PATHS.root}>
              <Image
                src={flagyardLogo}
                alt="flagyard-logo"
                width={110}
                height={30}
                duration={0}
                showLoading={<CircularProgress size={20} />}
              />
            </Link>
            {showUpgradeAction && (
              <Box sx={{
                display: {
                  xs: 'none',
                  [MOBILE_BREAKPOINT]: 'block',
                },
              }}
              >
                <UpgradeAction />
              </Box>
            )}
          </Typography>
          {renderAccountMenu()}
        </Toolbar>
      </Container>
    </MUIAppBar>
  );
};

export default memo(AppBar);
