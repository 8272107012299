import {
  Chip,
  Link,
} from '@mui/material';
import { GridActionsCellItem } from '@mui/x-data-grid';

import { CrossCircleIcon, OpenEyeIcon } from 'assets/icons';
import {
  useDate,
  useModal,
  useLocale,
} from 'util/hooks';

import MODAL_KEYS from 'assets/constants/modalKeys';
import { STATUS, getStatusByName } from 'assets/constants/statuses';

const staticNow = new Date();

const useTableColumns = () => {
  const { t } = useLocale();
  const { addModal } = useModal();
  const { format, isBefore } = useDate();

  const columns = [
    {
      field: 'name',
      headerName: t('common.name'),
      sortable: false,
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'status',
      headerName: t('common.status'),
      flex: 1,
      renderCell: ({ row }) => {
        const { isLive, isComingSoon } = row;

        let eventState = '';

        if (isLive) {
          eventState = STATUS.live;
        } else if (isComingSoon) {
          eventState = STATUS.comingUp;
        } else {
          eventState = STATUS.ended;
        }

        return (
          <Chip
            size="small"
            color={eventState || ''}
            label={t(getStatusByName(eventState)?.localizationKey) || ''}
          />
        );
      },
    },
    {
      field: 'type',
      headerName: t('common.type'),
      flex: 1,
    },
    {
      field: 'startDate',
      headerName: t('common.startDate'),
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row: { eventStartAt } }) => format(eventStartAt, 'PPpp'),
    },
    {
      field: 'endDate',
      headerName: t('common.endDate'),
      flex: 1,
      minWidth: 130,
      valueGetter: ({ row: { eventEndAt } }) => format(eventEndAt, 'PPpp'),
    },

    {
      field: 'actions',
      headerName: t('common.actions'),
      type: 'actions',
      flex: 1,
      minWidth: 50,
      getActions: ({ row }) => {
        const { name, registrationStartAt } = row;
        const disableRegistrationStartAt = isBefore(new Date(registrationStartAt), staticNow);

        const actions = [
          <GridActionsCellItem
            component={Link}
            icon={<OpenEyeIcon width="20" height="20" />}
            label={t('common.view')}
            to={name}
            sx={{ minWidth: 180 }}
            showInMenu
          />,
          <GridActionsCellItem
            disabled={disableRegistrationStartAt}
            icon={(
              <CrossCircleIcon
                width="20"
                height="20"
              />
            )}
            label={t('common.delete')}
            onClick={() => addModal({
              key: MODAL_KEYS.deleteEvent,
              props: {
                eventDetails: row,
              },
            })}
            sx={{ minWidth: 180 }}
            showInMenu
          />,
        ];

        return actions;
      },
    },
  ];

  return columns;
};

export default useTableColumns;
