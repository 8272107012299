import { useEventRecentSolves } from 'reactQuery/queries';
import { EventRecentSolvesTable } from 'components/tables';
import SOCKET_EVENTS from 'assets/constants/socketEvents';
import { useAudio, useModal, useSocket } from 'util/hooks';
import MODAL_KEYS from 'assets/constants/modalKeys';
import { firstBloodAudio } from 'assets/audio';

const DELAY = 5250;

const VisualizationLatestCaptures = () => {
  const { addModal } = useModal();
  const { toggle } = useAudio(firstBloodAudio);
  const {
    data: recentSolves = [],
    isFetching,
    refetch: refetchRecentSolves,
  } = useEventRecentSolves({
    queryKeyOptions: {
      pagination: {
        limit: 5,
      },
    },
  });

  const socketEvents = [
    {
      eventName: SOCKET_EVENTS.events.visualization,
      handler: (params) => {
        refetchRecentSolves();
        if (params.firstBlood) {
          toggle();
          setTimeout(() => {
            addModal({
              key: MODAL_KEYS.eventChallengeNewCaptureModal,
              modalProps: {
                disableAutoFocus: true,
              },
              props: {
                submission: {
                  ...params,
                },
              },
            });
          }, [DELAY]);
        }
      },
    },
  ];

  useSocket({ events: socketEvents });

  return (
    <EventRecentSolvesTable
      recentSolves={recentSolves}
      isFetching={isFetching}
    />
  );
};

export default VisualizationLatestCaptures;
