import { useMemo } from 'react';
import {
  Box,
  Badge,
  Stack,
  Typography,
  useMediaQuery,
  Card,
} from '@mui/material';

import { leaderboardRank1, leaderboardRank2, leaderboardRank3 } from 'assets/images';

import { BloodDropIcon, FlagIcon, SparklingIcon } from 'assets/icons';
import { AvatarWithFallback, TypographyWithIcon } from 'components/molecules';

const top3RankImage = {
  1: leaderboardRank1,
  2: leaderboardRank2,
  3: leaderboardRank3,
};
const top3RankBorderColor = {
  1: '#DEB16D',
  2: '#D2D3DA',
  3: '#8A3613',
};

const points = [
  { key: 'points', icon: SparklingIcon },
  { key: 'numberOfSolves', icon: FlagIcon },
  { key: 'firstBloods', icon: BloodDropIcon },
];

const gap = 10;
const breakpoint = 'lg';
const typographyWithIconStyle = {
  iconProps: {
    mx: 0,
    mr: 2,
    width: 20,
    height: 20,
  },
  labelProps: { variant: 'bodyLargeMedium' },
};

const Top3 = (props) => {
  const {
    top3 = [],
    handleRowClick,
  } = props;
  const mobileView = useMediaQuery(({ breakpoints }) => breakpoints.down(breakpoint));

  const top3Ordered = useMemo(() => {
    if (top3?.length) {
      const top3Copy = [...top3];
      if (mobileView) {
        return top3Copy;
      }

      top3Copy.splice(1, 0, top3Copy.splice(0, 1)[0]); // move first rank to middle
      return top3Copy;
    }
    return [];
  }, [mobileView, top3]);

  return (
    <Box
      sx={{
        gap,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: { xs: 'column', [breakpoint]: 'row' },
      }}
    >
      {top3Ordered.map((team) => {
        const {
          id,
          name,
          rank,
          logo,
        } = team;

        return (
          <Stack
            key={name}
            sx={{
              pt: gap,
              gap: 3,
              alignItems: 'center',
              mt: {
                xs: 0,
                [breakpoint]: (rank === 1 ? -(2 * gap) : 0),
              },
            }}
          >
            <Badge
              overlap="circular"
              anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
              badgeContent={(
                <img
                  src={top3RankImage[rank]}
                  alt={`rank-${rank}`}
                  width="75"
                  height="75"
                />
              )}
              sx={{
                '& .MuiBadge-badge': {
                  right: '50%',
                  transform: 'translate(50%, 150%)', // Fine-tune the position
                },
              }}
            >
              <AvatarWithFallback
                src={logo}
                alt={name}
                sx={{
                  width: 150,
                  height: 150,
                  border: `6px solid ${top3RankBorderColor[rank]}`,
                }}
              />
            </Badge>
            <Box
              onClick={() => handleRowClick(null, id)}
              sx={{
                mt: 5,
                '&:hover': {
                  cursor: 'pointer',
                },
              }}
            >
              <Typography
                variant="bodyLargeMedium"
                nowrap
                sx={{
                  maxWidth: 220,
                  overflow: 'hidden',
                  whiteSpace: 'nowrap',
                  display: 'block',
                  textOverflow: 'ellipsis',
                }}
              >
                {name}
              </Typography>
            </Box>
            <Card sx={{
              px: 9,
              py: 3,
              gap: 5,
              minWidth: 265,
              display: 'flex',
              justifyContent: 'center',
            }}
            >
              {points.map(({ key, icon: Icon }) => (
                <TypographyWithIcon
                  key={key}
                  label={team[key] || 0}
                  icon={(
                    <Icon
                      width="20"
                      height="20"
                    />
                  )}
                  {...typographyWithIconStyle}
                />
              ))}
            </Card>
          </Stack>
        );
      })}
    </Box>
  );
};

export default Top3;
