import {
  Box,
  Link,
} from '@mui/material';
import { LabAvatar } from 'components/molecules';

import { PATHS } from 'routes';
import { useLocale } from 'util/hooks';

const useTableColumns = (props = {}) => {
  const { t, isAr } = useLocale();

  const columns = [
    {
      field: 'labName',
      headerName: t('labs.labName'),
      sortable: false,
      flex: 1,
      minWidth: 170,
      renderCell: ({ row: { avatar, nameAr, nameEn } }) => (
        <>
          <LabAvatar
            src={avatar}
            alt={nameEn}
            sx={{
              mr: 3,
              width: 36,
              height: 36,
            }}
          />
          {isAr ? nameAr : nameEn}
        </>
      ),
    },
    {
      field: 'type',
      headerName: t('labs.type'),
      flex: 1,
      minWidth: 130,
    },
    {
      field: 'numberOfActiveChallenges',
      headerName: t('labs.activeChallenges'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'numberOfPendingChallenges',
      headerName: t('labs.pendingChallenges'),
      flex: 1,
      minWidth: 100,
    },
    {
      field: 'actions',
      headerName: t('common.actions'),
      flex: 1,
      minWidth: 100,
      renderCell: (params) => {
        const { row: { id } } = params;

        return (
          <Box>
            <Link
              to={`/${PATHS.labs}/${id}`}
              sx={{
                color: 'text.raspberry',
              }}
            >
              {t('labs.view')}
            </Link>
          </Box>
        );
      },
    },
  ];

  return columns;
};

export default useTableColumns;
