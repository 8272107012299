import { useMemo } from 'react';
import * as Yup from 'yup';

import {
  Box,
  Card,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { FormPasswordField } from 'components/form';
import { updatePasswordService } from 'services';
import {
  useFastForm, useSnackbar, useLocale, useRecaptcha,
} from 'util/hooks';
import { REGEX } from 'util/helpers';

const UpdatePassword = (props) => {
  const snack = useSnackbar();
  const { t } = useLocale();
  const { executeRecaptcha } = useRecaptcha({ action: 'UPDATE_PASSWORD' });

  const defaultValues = {
    oldPassword: '',
    newPassword: '',
  };

  const validationSchema = useMemo(() => Yup.object({
    oldPassword: Yup
      .string()
      .required(t('password.enterPassword')),
    newPassword: Yup
      .string()
      .matches(REGEX.password, t('password.invalidPassword'))
      .required(t('password.enterPassword'))
      .notOneOf([Yup.ref('oldPassword'), null], t('password.passwordsDifferent')),
  }), [t]);

  const onSubmit = async (values) => {
    try {
      const { oldPassword, newPassword } = values;
      const payload = {
        oldPassword,
        newPassword,
      };
      reset({});
      const recaptchaToken = await executeRecaptcha();
      await updatePasswordService(payload, { recaptchaToken });
      snack({
        severity: 'success',
        message: t('password.updatedSuccessfully'),
      });
    } catch (error) {
      const { errors } = error;
      reset({});

      errors.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property) {
          snack({
            severity: 'error',
            message: t('common.somethingWrong'),
          });
        }
      });
    }
  };

  const {
    control,
    handleSubmit,
    setError,
    reset,
    formState: { isSubmitting, isDirty, isValid },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const updatePasswordForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginBottom: 6,
        }}
      >
        <Typography variant="bodyStandardMedium" sx={{ color: 'text.lightGray' }}>
          {t('password.updatePassword')}
        </Typography>
        <Typography variant="bodySmallRegular" sx={{ color: 'text.secondary' }}>
          {t('password.choosePassword')}
        </Typography>
      </Box>
      <Box
        sx={{
          display: 'grid',
          columnGap: 12,
          rowGap: 8,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(2, 1fr)',
            lg: 'repeat(2, 304px)',
            xl: 'repeat(2, 420px)',
          },
        }}
      >
        <Box>
          <FormPasswordField
            name="oldPassword"
            control={control}
            showHelper={false}
            label={t('password.currentPassword')}
            autoComplete="new-password"
            fullWidth
          />
        </Box>
        <Box>
          <FormPasswordField
            name="newPassword"
            control={control}
            label={t('password.newPassword')}
            autoComplete="new-password"
            fullWidth
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 8,
        }}
      >
        <LoadingButton
          type="submit"
          variant="contained"
          size="medium"
          loading={isSubmitting}
          disabled={!isDirty || (isSubmitting || !isValid)}
        >
          {t('password.updatePassword')}
        </LoadingButton>
      </Box>
    </form>
  );

  return (
    <Card
      id="update-password-form"
      sx={{
        px: { xs: 6, sm: 14 },
        py: 8,
      }}
    >
      {updatePasswordForm}
    </Card>
  );
};

export default UpdatePassword;
