import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

import { RoleId } from 'routes/authRoles';

const UserAwareAvatar = (props) => {
  const {
    user,
    sx,
    ...rest
  } = props;

  const {
    role = {},
    isFreePlanUser = true,
  } = user || {};

  const isPlayer = role?.id === RoleId.player;
  const showPremiumBorder = isPlayer && !isFreePlanUser;

  return (
    <Avatar
      {...rest}
      sx={{
        border: (theme) => (showPremiumBorder
          ? `2px solid ${theme.palette.primary.main}`
          : null),
        ...sx,
      }}
    />
  );
};

UserAwareAvatar.propTypes = {
  user: PropTypes.oneOfType([PropTypes.object]),
  sx: PropTypes.oneOfType([PropTypes.object]),
};

UserAwareAvatar.defaultProps = {
  user: {},
  sx: {},
};

export default UserAwareAvatar;
