import {
  Box,
  Grid,
  Link,
  Stack,
  Avatar,
  Tooltip,
  Typography,
} from '@mui/material';

import {
  UserFilledIcon,
  BloodDropFilledIcon,
  SparklingFilledIcon,
  UserWithHashFilledIcon,
  BloodDropWithHashFilledIcon,
  SparklingWithHashFilledIcon,
} from 'assets/icons';

import { FirstBloodCalculation, InfoCard } from 'components/molecules';
import { capitalizeFirstLetter } from 'util/helpers';
import { useAuth, useLocale } from 'util/hooks';

const MachineStats = (props) => {
  const { machineDetails = {} } = props;

  const {
    userHacks = '',
    rootHacks = '',
    userPoints = '',
    rootPoints = '',
    userFirstBloodSolve = {},
    rootFirstBloodSolve = {},
  } = machineDetails || {};

  const { t } = useLocale();
  const { isPlayer } = useAuth();

  const renderFirstBloodUser = ({ player, submittedAt }) => (
    <Box sx={{
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap',
    }}
    >
      <Link to={`/profile/${player?.username}`}>
        <Stack direction="row" spacing={3}>
          <Box sx={{ width: 23, height: 23 }}>
            <Avatar
              src={String(player?.avatar)}
              alt={capitalizeFirstLetter(player?.username)}
              sx={{
                width: 23,
                height: 23,
                fontSize: 15,
                fontWeight: 'bold',
              }}
            />
          </Box>
          <Box>
            <Typography variant="bodyStandardBold" color="text.primary">
              {player?.username}
            </Typography>
          </Box>
        </Stack>
      </Link>
      <Box ml={3}>
        <Tooltip
          title={t('machines.firstBloodTimeDesc')}
          placement="top"
        >
          <Typography
            variant="bodySmallMedium"
            color="text.red"
          >
            <FirstBloodCalculation
              publishedAt={machineDetails?.publishedAt}
              submittedAt={submittedAt}
            />
          </Typography>
        </Tooltip>
      </Box>
    </Box>
  );

  const renderFirstBlood = (firstBloodSolve = {}) => {
    if (firstBloodSolve?.player) {
      return renderFirstBloodUser(firstBloodSolve);
    }
    return (
      <Typography variant="bodyStandardBold">
        {t('machines.notClaimedYet')}
      </Typography>
    );
  };

  const renderMachineHacks = (hacks = '') => (
    <Typography variant="bodyStandardBold">
      {`${hacks} ${t('challenges.hacks')}`}
    </Typography>
  );

  const renderMachinePoints = (points = '') => (
    <Typography variant="bodyStandardBold">
      {`${points} ${t('machines.points')}`}
    </Typography>
  );

  const userStats = [
    {
      key: 'user-points',
      icon: <SparklingFilledIcon width="45" height="45" />,
      title: t('machines.userPoints'),
      component: renderMachinePoints(userPoints),
      show: isPlayer,
    },
    {
      key: 'user-hacks',
      icon: <UserFilledIcon width="45" height="45" />,
      title: t('machines.userHacks'),
      component: renderMachineHacks(userHacks),
      show: true,
    },
    {
      key: 'user-blood',
      icon: <BloodDropFilledIcon width="45" height="45" />,
      title: t('machines.userBlood'),
      component: renderFirstBlood(userFirstBloodSolve),
      show: true,
    },
  ];

  const rootStats = [
    {
      key: 'root-points',
      icon: <SparklingWithHashFilledIcon width="45" height="45" />,
      title: t('machines.rootPoints'),
      component: renderMachinePoints(rootPoints),
      show: isPlayer,
    },
    {
      key: 'root-hacks',
      icon: <UserWithHashFilledIcon width="45" height="45" />,
      title: t('machines.rootHacks'),
      component: renderMachineHacks(rootHacks),
      show: true,
    },
    {
      key: 'root-blood',
      icon: <BloodDropWithHashFilledIcon width="45" height="45" />,
      title: t('machines.rootBlood'),
      component: renderFirstBlood(rootFirstBloodSolve),
      show: true,

    },
  ];

  const renderStatsCards = (list) => list.map((stat) => (
    stat.show
    && (
    <InfoCard
      size="small"
      {...stat}
    />
    )
  ));

  return (
    <>
      <Box mb={4}>
        <Grid
          container
          columnSpacing={6}
          rowSpacing={4}
        >
          {renderStatsCards(userStats)}
        </Grid>
      </Box>
      <Grid
        container
        columnSpacing={6}
        rowSpacing={4}
      >
        {renderStatsCards(rootStats)}
      </Grid>
    </>
  );
};
export default MachineStats;
