import Avatar from '@mui/material/Avatar';

const LabAvatar = (props) => {
  const {
    src = '',
    sx = {},
    ...rest
  } = props;

  return (
    <Avatar
      src={String(src)}
      sx={{
        p: 2, // Handle overflow for large avatars
        ...sx,
      }}
      {...rest}
    />
  );
};

export default LabAvatar;
