import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  Stack,
  Typography,
} from '@mui/material';

import { InviteIcon } from 'assets/icons';
import {
  FormCheckbox,
  FormTextField,
  FormFileUpload,
} from 'components/form';
import {
  useLocale,
  useFastForm,
  useSnackbar,
} from 'util/hooks';
import { inviteUsers } from 'services';
import { isEmpty } from 'lodash';
import { PATHS } from 'routes';

const EventInvitationForm = () => {
  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { eventName } = useParams();

  const onSubmit = async (values) => {
    const {
      inviteAnother,
      email = '',
      invitationList,
    } = values;

    const singleEmailOrList = isEmpty(invitationList)
      ? { email } : { fileId: invitationList[0]?.fileId };

    const payload = {
      ...singleEmailOrList,
      event: { name: eventName },
    };

    try {
      await inviteUsers(payload);
      if (inviteAnother) {
        reset({});
      } else {
        navigateToEvent();
      }

      snack({
        message: `${t('invitation.inviteMessage.invitation')}
         ${email} ${t('invitation.inviteMessage.confirm')}`,
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: (error.errors[0].message || error?.message || t('common.somethingWrong')),
        severity: 'error',
      });
    }
  };

  const navigateToEvent = () => {
    navigate(`/${PATHS.events}/${eventName}/${PATHS.invitations}`);
  };

  const defaultValues = {
    email: '',
    invitationList: [],
    inviteAnother: false,
  };

  const validationSchema = Yup.object({
    email: Yup
      .string()
      .email(t('email.validation'))
      .when('invitationList', {
        is: (invitationList) => invitationList.length <= 0,
        then: Yup.string()
          .required(t('invitation.enterEmail')),
      }),
    invitationList: Yup
      .array()
      .of(Yup
        .object()
        .shape({
          path: Yup.string(),
          fileName: Yup.string(),
        })),
  });

  const {
    control,
    handleSubmit,
    reset,
    formState: {
      isSubmitting,
      isValid,
      isDirty,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const invitationForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'grid',
        columnGap: 12,
        gridTemplateColumns: {
          md: 'repeat(1, 1fr)',
          lg: 'repeat(2, 304px)',
          xl: 'repeat(2, 420px)',
        },
      }}
      >
        <Box sx={{ mt: 6 }}>
          <FormTextField
            name="email"
            type="email"
            control={control}
            label={t('common.email')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box sx={{ mt: 6 }}>
          <FormFileUpload
            dropzoneProps={{
              multiple: false,
              accept: { 'application/vnd.ms-excel': ['.csv'], 'text/plain': ['.csv'] },
            }}
            name="invitationList"
            control={control}
            label={t('events.invite.multipleDes')}
            buttonLabel={t('events.invite.uploadList')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: { xs: 'start', sm: 'end' },
        flexWrap: 'wrap',
        mt: 8,
      }}
      >
        <Box>
          <FormCheckbox
            name="inviteAnother"
            control={control}
            label={t('invitation.inviteAnother')}
          />
        </Box>

        <Stack direction="row" gap={3}>
          <Button onClick={navigateToEvent} disabled={isSubmitting}>
            {t('common.cancel')}
          </Button>

          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid || !isDirty || isSubmitting}
          >
            {t('invitation.sendInvitation')}
          </LoadingButton>
        </Stack>
      </Box>
    </form>
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      alignItems: {
        xs: 'center',
        md: 'start',
      },
    }}
    >
      <Box sx={{ width: 48, height: 44 }}>
        <InviteIcon width="48" height="44" />
      </Box>
      <Box sx={{
        width: {
          md: 1,
        },
        display: 'flex',
        flexDirection: 'column',
        mx: 5,
      }}
      >
        <Typography variant="bodyStandardMedium" sx={{ color: 'text.lightGray' }}>
          {t('invitation.inviteMembers')}
        </Typography>
        <Typography variant="bodySmallRegular" sx={{ color: 'text.secondary' }}>
          {t('invitation.inviteHeader')}
        </Typography>
        <Box>
          {invitationForm}
        </Box>
      </Box>
    </Box>
  );
};

export default EventInvitationForm;
