import { useParams } from 'react-router-dom';
import { playerGetLeaderboardTeamDetails } from 'services';
import { useCustomQuery } from 'reactQuery';
import { STATUS } from 'assets/constants/statuses';

const transformTeamDetails = (team) => ({
  ...team,
  isBanned: team.status === STATUS.banned,
});

const usePlayerLeaderboardTeamDetails = (queryProps = {}) => {
  const { teamId, options, ...rest } = queryProps;
  const { eventName } = useParams();

  const query = useCustomQuery({
    queryKey: [`${eventName}-player-leaderboard-team-details-${teamId}`],
    queryFn: () => playerGetLeaderboardTeamDetails(eventName, teamId),
    options: {
      enabled: !!eventName && !!teamId,
      select: transformTeamDetails,
      ...options,
    },
    ...rest,
  });

  return query;
};

export default usePlayerLeaderboardTeamDetails;
