const RoleId = {
  admin: 1,
  technicalAdmin: 2,
  businessAdmin: 3,
  accountManager: 4,
  labManager: 5,
  player: 6,
};

export const USER_ROLES = [
  {
    id: RoleId.admin,
    name: 'admin',
    localizationKey: 'roles.admin',
    invitable: false,
    dependents: [
      RoleId.admin,
      RoleId.businessAdmin,
      RoleId.technicalAdmin,
      RoleId.accountManager,
      RoleId.labManager,
      RoleId.player,
    ],
  },
  {
    id: RoleId.technicalAdmin,
    name: 'technicalAdmin',
    localizationKey: 'roles.technicalAdmin',
    invitable: true,
    dependents: [
      RoleId.labManager,
      RoleId.player,
    ],
  },
  {
    id: RoleId.businessAdmin,
    name: 'businessAdmin',
    localizationKey: 'roles.businessAdmin',
    invitable: true,
    dependents: [
      RoleId.accountManager,
    ],
  },
  {
    id: RoleId.accountManager,
    name: 'accountManager',
    localizationKey: 'roles.accountManager',
    invitable: true,
    dependents: [],
  },
  {
    id: RoleId.labManager,
    name: 'labManager',
    localizationKey: 'roles.labManager',
    invitable: true,
    dependents: [],
  },
  {
    id: RoleId.player,
    name: 'player',
    localizationKey: 'roles.player',
    invitable: false,
    dependents: [],
  },
];

export const getRoleById = (id) => USER_ROLES.find((role) => role.id === Number(id));
export const getRoleByName = (name) => USER_ROLES.find((role) => role.name === String(name));
export const getRoleByKey = (key, value) => USER_ROLES.find((role) => role[key] === value);
export const getInvitableRoles = () => USER_ROLES.filter((role) => role.invitable);
export const getDependentsForRole = (id) => getRoleById(id)?.dependents
  .map((dependentId) => getRoleById(dependentId));
