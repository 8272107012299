import END_POINTS from 'services/endPoints';
import api from '../api';

const getEventChallenges = (eventName) => api(
  END_POINTS.eventChallenges(eventName),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventChallenges;
