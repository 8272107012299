import END_POINTS from 'services/endPoints';
import downloadApi from '../downloadApi';

const downloadEventChallengeFiles = (eventName, challengeId, options) => downloadApi({
  path: END_POINTS.eventChallengeFiles(eventName, challengeId),
  exportType: 'zip',
  fileName: `challenge-files-${challengeId}.zip`,
  ...options,
})
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default downloadEventChallengeFiles;
