import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

const cancelEventInvitation = (eventName) => api(
  END_POINTS.eventInvitation(eventName),
  {
    method: METHODS.DELETE,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default cancelEventInvitation;
