import { useState, useTransition } from 'react';
import { Box } from '@mui/material';

import { SearchField } from 'components/molecules';
import { useLocale } from 'util/hooks';
import { useSearchParams } from 'react-router-dom';

const SearchToolbar = (props) => {
  const { t } = useLocale();
  const [, startTransition] = useTransition();
  const [searchParams, setSearchParams] = useSearchParams();
  const [value, setValue] = useState(searchParams.get('q') || '');

  const handleChange = (e) => {
    const q = e.target.value;
    setValue(q);
    startTransition(() => {
      setSearchParams(q ? { q } : {});
    });
  };

  const handleClear = () => {
    setValue('');
    setSearchParams({});
  };

  return (
    <Box sx={{ mb: 6 }}>
      <SearchField
        placeholder={t('common.search')}
        sx={{
          width: {
            xs: 1,
          },
          maxWidth: {
            sm: 300,
          },
          '& .MuiInput-underline:before': {
            borderBottom: 0,
          },
        }}
        value={value}
        onChange={handleChange}
        onClear={handleClear}
        {...props}
      />
    </Box>
  );
};

export default SearchToolbar;
