import { Stack } from '@mui/material';

import { useSocket } from 'util/hooks';
import { useEventLeaderboard } from 'reactQuery/queries';
import { EventLeaderBoardTable } from 'components/tables';
import SOCKET_EVENTS from 'assets/constants/socketEvents';

import Top3 from '../leaderBoard/Top3';

const GAP = 10;

const VisualizationLeaderboard = () => {
  const {
    data: { items = [] } = {},
    isFetching = true,
    refetch: refetchLeaderboard,
  } = useEventLeaderboard({
    queryKeyOptions: {
      pagination: {
        limit: 10,
      },
    },
  });

  const socketEvents = [
    {
      eventName: SOCKET_EVENTS.events.leaderboardUpdated,
      handler: () => {
        refetchLeaderboard();
      },
    },
  ];

  useSocket({ events: socketEvents });

  const leaderBoard = [...items]; // used for splice
  const topThreeRange = leaderBoard?.slice(0, 3)
    .filter((team) => team.rank !== null); // get the correct range to splice (exclude null rank)

  const top3 = leaderBoard?.splice(0, topThreeRange.length) || [];

  return (
    <Stack gap={GAP}>
      <Top3 top3={top3} />
      <EventLeaderBoardTable
        leaderBoard={leaderBoard}
        isFetching={isFetching}
      />
    </Stack>
  );
};

export default VisualizationLeaderboard;
