import { Stack } from '@mui/material';

import { useAuth, useLocale } from 'util/hooks';
import { useEventDetails, useEventTeamActivity, usePlayerTeamDetails } from 'reactQuery/queries';
import { PlayerActivityCard } from 'components/organisms';
import { NoContentPlaceholder } from 'components/molecules';
import { useParams } from 'react-router-dom';

const EventTeamActivity = () => {
  const { t } = useLocale();
  const { isPlayer } = useAuth();
  const { teamId } = useParams();

  const {
    data: playerTeamDetails = {},
  } = usePlayerTeamDetails({
    options: {
      enabled: isPlayer,
    },
  });

  const eventTeamId = isPlayer ? playerTeamDetails?.id : teamId;

  const {
    data: latestActivity = [],
    isFetched = false,
  } = useEventTeamActivity({ eventTeamId });

  const { data: eventDetails = {} } = useEventDetails();
  const { eventHasStarted = false } = eventDetails || {};

  const hasSubmissions = latestActivity?.length;

  const activityList = latestActivity?.map((activity) => (
    <PlayerActivityCard
      key={activity.id}
      activity={activity}
      eventHasStarted={eventHasStarted}
      showPoints
    />
  ));

  if (isFetched && !hasSubmissions) {
    const content = {
      desc: [{
        text: t('events.teams.noActivity'),
        variant: 'bodyStandardRegular',
      }],
    };

    return (
      <Stack sx={{
        alignItems: 'center',
        justifyContent: 'center',
      }}
      >
        <NoContentPlaceholder content={content} />
      </Stack>
    );
  }

  return (
    <Stack spacing={5}>
      {activityList}
    </Stack>
  );
};

export default EventTeamActivity;
