import END_POINTS from 'services/endPoints';
import api from '../api';

const getEventChallengeSubmissions = (eventName, challengeId, options) => api(
  END_POINTS.eventChallengeSubmissions(eventName, challengeId),
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventChallengeSubmissions;
