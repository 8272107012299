import {
  Box,
  Card,
  Link,
  Stack,
  Typography,
} from '@mui/material';

import { useDate, useLocale } from 'util/hooks';
import {
  FlagIcon,
  BloodDropIcon,
  FlagWithHashIcon,
  BloodDropWithHashIcon,
} from 'assets/icons';

import { UserAwareAvatar } from 'components/molecules';
import { capitalizeFirstLetter } from 'util/helpers';

const MachineActivityCard = (props) => {
  const {
    submission = {},
  } = props;

  const {
    player = {},
    submittedAt = '',
    userFirstBlood = false,
    rootFirstBlood = false,
    isRootSubmission = false,
  } = submission || {};

  const {
    avatar = '',
    username = '',
  } = player || {};

  const { t } = useLocale();
  const { fromNow } = useDate();

  const iconSize = { width: 21, height: 21 };
  const submittedAtString = fromNow(submittedAt);

  // Content
  const getCardContent = () => {
    if (isRootSubmission) {
      return rootFirstBlood
        ? t('machines.claimedRootBlood')
        : t('machines.gotRootFlag');
    }
    return userFirstBlood
      ? t('machines.claimedUserBlood')
      : t('machines.gotUserFlag');
  };

  // Icons
  const getCardIcons = () => {
    if (isRootSubmission) {
      return rootFirstBlood
        ? <BloodDropWithHashIcon {...iconSize} />
        : <FlagWithHashIcon {...iconSize} />;
    }
    return userFirstBlood
      ? <BloodDropIcon {...iconSize} />
      : <FlagIcon {...iconSize} />;
  };

  // Avatar
  const avatarSize = {
    width: { xs: 18, sm: 36 },
    height: { xs: 18, sm: 36 },
  };

  const userAvatar = (
    <UserAwareAvatar
      src={avatar}
      alt={capitalizeFirstLetter(username)}
      sx={{
        ...avatarSize,
        fontWeight: 'bold',
        fontSize: 18,
      }}
    />
  );

  // Activity
  const activityContent = (
    <Stack
      spacing={2}
      direction="row"
      sx={{ flexWrap: 'wrap', alignItems: 'center' }}
    >
      <Box>
        <Link to={`/profile/${username}`}>
          <Stack
            spacing={2}
            direction="row"
            alignItems="center"
          >
            <Box sx={{
              ...avatarSize,
            }}
            >
              {userAvatar}
            </Box>
            <Typography
              variant="bodySmallMedium"
              color="text.primary"
            >
              {username}
            </Typography>
          </Stack>
        </Link>
      </Box>
      <Typography
        variant="bodyStandardRegular"
        color="text.secondary"
      >
        {getCardContent()}
      </Typography>
    </Stack>
  );

  return (
    <Card
      sx={{
        px: 8,
        py: 4.5,
      }}
    >
      <Box sx={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        flexWrap: 'wrap',
        rowGap: 1,
      }}
      >
        <Box sx={{
          display: 'flex',
          alignItems: 'center',
          width: {
            xs: 1,
            sm: 0.85,
          },
        }}
        >
          <Box sx={{
            ...iconSize,
            mr: 3,
          }}
          >
            {getCardIcons()}
          </Box>
          <Box>
            {activityContent}
          </Box>
        </Box>
        <Box>
          <Typography
            variant="bodySmallRegular"
            color="text.lightGray"
          >
            {submittedAtString}
          </Typography>
        </Box>
      </Box>
    </Card>
  );
};

export default MachineActivityCard;
