import END_POINTS from '../../endPoints';
import api from '../../api';

const getEventInvitations = (eventName, options) => api(
  END_POINTS.eventInvitation(eventName),
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventInvitations;
