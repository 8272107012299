import { useParams } from 'react-router-dom';

import { getEventChallengeSubmissions } from 'services';
import { useCustomQuery } from 'reactQuery';

const useEventChallengeSubmissions = (queryProps = {}) => {
  const { eventName, challengeId } = useParams();

  const query = useCustomQuery({
    queryKey: [`event-challenge-submissions-${challengeId}`],
    queryFn: (options) => getEventChallengeSubmissions(eventName, challengeId, options),
    ...queryProps,
    options: {
      enabled: !!eventName && !!challengeId,
      ...queryProps?.options,
    },
  });

  return { ...query };
};

export default useEventChallengeSubmissions;
