import PropTypes from 'prop-types';

import {
  Card,
  CardContent,
  Typography,
  Link,
  Box,
} from '@mui/material';

import { useLocale } from 'util/hooks';
import { LabAvatar } from 'components/molecules';

const LabCard = (props) => {
  const {
    lab,
  } = props;

  const { t, isAr } = useLocale();

  const {
    id = '',
    avatar = '',
    nameAr = '',
    nameEn = '',
    numberOfChallenges = '',
  } = lab || {};

  return (
    <Card
      sx={{
        height: 271,
        minWidth: 264,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Link to={String(id)}>
        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box sx={{
            display: 'flex',
            width: 164,
            height: 164,
          }}
          >
            <LabAvatar
              src={avatar}
              alt={nameEn}
              sx={{
                width: 164,
                height: 164,
              }}
            />
          </Box>
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            textAlign: 'center',
            mt: 5,
          }}
          >
            <Typography
              variant="bodyMediumMedium"
              color="text.lighterGray"
            >
              {isAr ? nameAr : nameEn}
            </Typography>
            <Typography
              variant="bodyStandardMedium"
              color="text.secondary"
            >
              {`${numberOfChallenges} ${t('challenges.challenges')}`}
            </Typography>
          </Box>
        </CardContent>
      </Link>
    </Card>
  );
};

LabCard.propTypes = {
  lab: PropTypes.oneOfType([PropTypes.object]),
};

LabCard.defaultProps = {
  lab: {},
};

export default LabCard;
