import {
  Box,
  Card,
  Typography,
} from '@mui/material';

import {
  DownloadIcon,
} from 'assets/icons';
import {
  useLocale, useAuth, usePermission, useDate,
} from 'util/hooks';
import PERMISSIONS from 'assets/constants/permissions';
import { useNavigate } from 'react-router-dom';
import { STATUS } from 'assets/constants/statuses';
import AnnouncementSectionWrapper from '../helpers/AnnouncementSectionWrapper';

const AnnouncementInfo = (props) => {
  const { announcementDetails = {} } = props;
  const { currentUser, isAdmin, isBusinessAdmin } = useAuth();
  const { username } = currentUser;

  const { t } = useLocale();
  const navigate = useNavigate();

  const { formatDate, formatTime } = useDate();

  const {
    title,
    startAt,
    endAt,
    createdBy,
    image,
    status,
  } = announcementDetails || {};

  const isDraft = status === STATUS.draft;
  const isExpired = status === STATUS.expired;
  const canEdit = usePermission(PERMISSIONS.announcementUpdate);
  const canEditAll = isAdmin || isBusinessAdmin;
  const isOwnAnnouncement = createdBy === username;
  const canEditThisAnnouncement = !isExpired
    && (canEditAll || (canEdit && isOwnAnnouncement && isDraft));

  const onClickEdit = () => navigate(`/announcements/${announcementDetails?.id}/edit`);

  const renderAnnouncementDetails = () => (
    <Box
      sx={{
        display: 'grid',
        rowGap: 16,
      }}
    >
      <Box
        sx={{
          display: 'grid',
          columnGap: 31,
          rowGap: 16,
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(1, 420px)',
            lg: 'repeat(2, 304px)',
            xl: 'repeat(2, 420px)',
          },
        }}
      >
        <Box>
          <Box>
            <Typography
              variant="bodyStandardBold"
              color="text.secondary"
            >
              {t('common.title')}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="bodyMediumBold"
              color="text.lighterGray"
            >
              {title}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography
              variant="bodyStandardBold"
              color="text.secondary"
            >
              {t('common.createdBy')}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="bodyMediumBold"
              color="text.lighterGray"
            >
              {createdBy}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography
              variant="bodyStandardBold"
              color="text.secondary"
            >
              {t('announcements.startDateAndTime')}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="bodyMediumBold"
              color="text.lighterGray"
            >
              {`${formatDate(startAt)} ${t('common.at')} ${formatTime(startAt)}`}
            </Typography>
          </Box>
        </Box>
        <Box>
          <Box>
            <Typography
              variant="bodyStandardBold"
              color="text.secondary"
            >
              {t('announcements.endDateAndTime')}
            </Typography>
          </Box>
          <Box>
            <Typography
              variant="bodyMediumBold"
              color="text.lighterGray"
            >
              {`${formatDate(endAt)} ${t('common.at')} ${formatTime(endAt)}`}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box maxWidth="md">
        <Box mb={4}>
          <Typography
            variant="bodyStandardBold"
            color="text.secondary"
          >
            {t('common.image')}
          </Typography>
        </Box>
        <Box>
          <Box sx={{
            mb: 4,
            maxWidth: 600,
            maxHeight: 600,
          }}
          >
            <img
              src={image}
              alt="announcement"
              style={{
                width: '100%',
                height: '100%',
              }}
            />
          </Box>
          <Box sx={{
            mt: 4,
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <Box mr={3}>
              <DownloadIcon />
            </Box>
            <Box>
              <a
                href={image}
                target="_blank"
                rel="noreferrer noopener"
                style={{ textDecoration: 'none' }}
              >
                <Typography
                  variant="bodyStandardBold"
                  color="text.raspberry"
                >
                  {t('common.downloadImage')}
                </Typography>
              </a>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ width: 1 }}>
      <Card
        sx={{
          px: { xs: 6, sm: 14 },
          py: 15,
        }}
      >
        <AnnouncementSectionWrapper
          title={t('announcements.announcementDetails')}
          canEditThisAnnouncement={canEditThisAnnouncement}
          onClickEdit={onClickEdit}
        >
          {renderAnnouncementDetails()}
        </AnnouncementSectionWrapper>
      </Card>
    </Box>
  );
};

export default AnnouncementInfo;
