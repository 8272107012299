import React from 'react';
import { STATUS } from 'assets/constants/statuses';
import { useNavigate } from 'react-router-dom';
import { useQueryClient } from '@tanstack/react-query';
import { useRecoilState, useResetRecoilState } from 'recoil';

import { SocketContext } from 'context';
import { authAtom } from 'recoil/atoms';
import { authRoles, PATHS } from 'routes';
import { getMyInfo, logoutService } from 'services';
import { removeItem, setItem, storageKeys } from 'util/localStorage';

const useAuth = () => {
  const queryClient = useQueryClient();
  const socket = React.useContext(SocketContext);
  const resetAuth = useResetRecoilState(authAtom);
  const [authState, setAuthState] = useRecoilState(authAtom);

  const {
    user: currentUser = {},
    isLoggedIn = false,
  } = authState;

  const { userRole = null } = currentUser;

  const navigate = useNavigate();

  const login = React.useCallback((data) => {
    const {
      user,
      accessToken,
    } = data;

    setAuthState({
      isLoggedIn: !!accessToken,
      accessToken,
      user: {
        ...user,
        avatar: String(user.avatar),
        userRole: user.role.name,
        isActive: user.status === STATUS.active,
      },
    });
    setItem(storageKeys.accessToken, accessToken);
  }, [setAuthState]);

  /**
   * Refresh current user
   */
  const refreshUser = React.useCallback(async () => {
    if (isLoggedIn) {
      const refreshedUserData = await getMyInfo();

      setAuthState((prv) => ({
        ...prv,
        user: {
          ...authState.user,
          ...refreshedUserData,
          avatar: String(refreshedUserData.avatar),
          userRole: refreshedUserData.role.name,
          isActive: refreshedUserData.status === STATUS.active,
        },
      }));
    }
  }, [authState, isLoggedIn, setAuthState]);

  /**
   * Update current user data
   * @param {*} newUser - new user data
   */
  const updateCurrentUser = React.useCallback((newUser) => {
    setAuthState((prv) => ({
      ...prv,
      user: {
        ...authState.user,
        ...newUser,
      },
    }));
  }, [authState, setAuthState]);

  const logout = React.useCallback(() => {
    socket?.off();
    socket?.disconnect();
    logoutService();
    removeItem(storageKeys.accessToken);
    resetAuth();
    navigate(PATHS.root);
    queryClient.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetAuth, socket, queryClient]);

  const roleBooleans = React.useMemo(() => ({
    isAdmin: userRole === authRoles.admin,
    isTechnicalAdmin: userRole === authRoles.technicalAdmin,
    isLabManager: userRole === authRoles.labManager,
    isBusinessAdmin: userRole === authRoles.businessAdmin,
    isAccountManager: userRole === authRoles.accountManager,
    isPlayer: userRole === authRoles.player,
    isManager: userRole === authRoles.admin
      || userRole === authRoles.technicalAdmin
      || userRole === authRoles.labManager,
  }), [userRole]);

  return {
    authState,
    // setAuthState,
    // resetAuth,
    currentUser,
    isLoggedIn,
    login,
    logout,
    refreshUser,
    updateCurrentUser,
    ...roleBooleans,
    isVerified: !!authState.user.emailVerifyAt,
  };
};

export default useAuth;
