import { isEmpty } from 'lodash';
import { useLocale } from 'util/hooks';

import {
  Box,
  Typography,
} from '@mui/material';

import { LabAvatar } from 'components/molecules';

const LabHeader = (props) => {
  const { labDetails = {} } = props;

  const {
    avatar,
    nameAr,
    nameEn,
    createdBy,
  } = labDetails;

  const { t, isAr } = useLocale();
  const viewMode = !isEmpty(labDetails);

  return (
    <Box sx={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      flexWrap: 'wrap',
      my: 7,
    }}
    >
      <Box sx={{
        display: 'flex',
        alignItems: 'center',
      }}
      >
        {viewMode && (
          <Box sx={{ mr: 4 }}>
            <LabAvatar
              src={avatar}
              alt={nameEn}
              sx={{
                width: 65,
                height: 65,
              }}
            />
          </Box>
        )}
        <Box>
          <Typography variant="bodyLargeBold">
            {isAr ? nameAr : nameEn}
          </Typography>
        </Box>
      </Box>
      {viewMode
        && (
          <Box sx={{ mt: { xs: 2, md: 0 } }}>
            <Typography variant="bodyStandardRegular" color="text.mediumGray">
              {`${t('common.createdBy')} `}
            </Typography>
            <Typography variant="bodyStandardBold">
              {createdBy}
            </Typography>
          </Box>
        )}
    </Box>
  );
};

export default LabHeader;
