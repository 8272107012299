import { useLocale, useAuth, usePermission } from 'util/hooks';
import {
  Box,
  Card,
} from '@mui/material';
import { ChallengeDetailIcon } from 'assets/icons';
import { useState } from 'react';
import { ChallengeDetailsForm } from 'components/organisms';

import PERMISSIONS from 'assets/constants/permissions';
import { STATUS } from 'assets/constants/statuses';
import ChallengeSectionWrapper from '../helpers/ChallengeSectionWrapper';
import ChallengeDetailsWrapper from '../helpers/ChallengeDetailsWrapper';

const ChallengeInfo = (props) => {
  const { challengeDetails = {} } = props;

  const { t } = useLocale();
  const {
    currentUser: { username },
    isAdmin,
    isTechnicalAdmin,
  } = useAuth();

  const [viewMode, setViewMode] = useState(true);

  const isArchived = challengeDetails?.status === STATUS.archived;
  const canEdit = usePermission(PERMISSIONS.challengesEdit);
  const canEditAll = isAdmin || isTechnicalAdmin;
  const isOwnChallenge = challengeDetails?.createdBy === username;
  const isEventHasEnded = challengeDetails?.eventHasEnded || false;

  const canEditChallenge = viewMode
    && !isArchived
    && (canEditAll || (isOwnChallenge && canEdit))
    && !isEventHasEnded;

  return (
    <Box>
      <Card sx={{ p: 14 }}>
        <ChallengeSectionWrapper
          icon={<ChallengeDetailIcon />}
          title={t('challenges.challengeDetails')}
          showAction={canEditChallenge}
          actionLabel={t('common.edit')}
          onClickAction={() => {
            setViewMode(false);
          }}
        />
        {viewMode
          && (
            <Box sx={{ mt: 4 }}>
              <ChallengeDetailsWrapper challengeDetails={challengeDetails} />
            </Box>
          )}
        {
          !viewMode
          && (
            <Box sx={{ mt: 4 }}>
              <ChallengeDetailsForm
                onClickCancel={() => setViewMode(true)}
              />
            </Box>
          )
        }
      </Card>
    </Box>
  );
};

export default ChallengeInfo;
