import {
  useState,
  useMemo,
  useEffect,
} from 'react';

import { Box } from '@mui/material';

import { DataList } from 'components/core';
import { PATHS } from 'routes';

import { useAuth } from 'util/hooks';
import { STATUS } from 'assets/constants/statuses';

import { useRecoilState } from 'recoil';
import { challengesFilterAtom } from 'recoil/atoms';
import VISIBILITIES from 'assets/constants/visibilities';
import ASSIGNMENTS from 'assets/constants/assignments';
import { ChallengeActions } from './Sections';
import ChallengeToolbar from './Sections/ChallengeToolbar';
import useListColumns from './useListColumns';

const ChallengeList = (props) => {
  const {
    status = '',
    challenges = [],
  } = props;

  // List options & permissions
  const {
    isBusinessAdmin,
    isAccountManager,
  } = useAuth();

  const {
    isActiveList,
    isArchivedList,
    isPendingList,
    isRetiredList,
    isDraftList,
    checkboxSelection,
    showActions,
  } = useMemo(() => ({
    isActiveList: status === STATUS.active,
    isDraftList: status === STATUS.draft,
    isPendingList: status === STATUS.pending,
    isRetiredList: status === STATUS.retired,
    isArchivedList: status === STATUS.archived,
    checkboxSelection: !(status === STATUS.archived),
    showActions: !(status === STATUS.archived),
  }), [status]);

  // List state
  const [selectedRows, setSelectedRows] = useState([]);
  const columns = useListColumns({ isArchivedList, isDraftList, isPendingList });

  // Filter state
  const isBusinessUser = isBusinessAdmin || isAccountManager;
  const showVisibleFilter = !isBusinessUser && (isActiveList || isRetiredList);
  const showAssignedFilter = isPendingList;
  const showFilter = showVisibleFilter || showAssignedFilter;

  const [filter, setFilter] = useRecoilState(challengesFilterAtom);

  useEffect(() => {
    const initialFilter = {};
    if (showVisibleFilter) {
      initialFilter.type = 'visibility';
      initialFilter.value = VISIBILITIES.visible;
    } else if (showAssignedFilter) {
      initialFilter.type = 'assignment';
      initialFilter.value = ASSIGNMENTS.unassigned;
    }

    // Step 2 - initialize filter
    setFilter(initialFilter);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showAssignedFilter, showVisibleFilter]);

  const rows = useMemo(() => {
    switch (filter.value) {
      case VISIBILITIES.visible:
        return challenges.filter((challenge) => challenge.visible);
      case VISIBILITIES.hidden:
        return challenges.filter((challenge) => !challenge.visible);
      case ASSIGNMENTS.assigned:
        return challenges.filter((challenge) => challenge.assigned);
      case ASSIGNMENTS.unassigned:
        return challenges.filter((challenge) => !challenge.assigned);
      default:
        return challenges;
    }
  }, [challenges, filter.value]);

  return (
    <>
      <ChallengeToolbar
        rows={rows}
        status={status}
        showFilter={showFilter}
      />
      <DataList
        rows={rows}
        columns={columns}
        withSearch={false}
        checkboxSelection={checkboxSelection}
        selectionModel={selectedRows.map((r) => r.id)}
        onSelectionModelChange={(newSelectionModel) => {
          // Convert ids to row object
          const selectedIDs = new Set(newSelectionModel);
          const selectedRowData = rows.filter((row) => selectedIDs.has(row.id));
          setSelectedRows(selectedRowData);
        }}
        useLinkRow
        componentsProps={{
          noRowsOverlay: { type: 'challenges' },
          row: { toPath: PATHS.challenges },
        }}
      />
      {showActions && (
        <Box sx={{
          minHeight: 48,
          mt: 9,
        }}
        >
          <ChallengeActions
            listView
            status={status}
            challenges={selectedRows}
          />
        </Box>
      )}
    </>
  );
};

export default ChallengeList;
