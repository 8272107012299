import {
  Avatar, Box, Typography, useTheme,
} from '@mui/material';
import {
  FlagIcon,
  BloodDropIcon,
  SparklingIcon,
} from 'assets/icons';
import { ColoredCircle, TypographyWithIcon } from 'components/molecules';

const typographyWithIconStyle = {
  iconProps: {
    mx: 0,
    mr: 2,
    width: 20,
    height: 20,
  },
  labelProps: { variant: 'bodyLargeMedium' },
};

const useTableColumns = () => {
  const { typography } = useTheme();

  return [
    {
      field: 'rank',
      headerName: 'rank',
      sortable: true,
      flex: 0.3,
      minWidth: 100,
      renderCell: ({ row: { rank } }) => (
        <Box sx={{ pl: { xs: 2, sm: 8 } }}>
          <ColoredCircle
            value={rank || '-'}
            sx={{
              width: '32px',
              height: '32px',
              color: 'text.secondary',
              backgroundColor: 'paper.hover',
              ...typography.bodyMediumMedium,
            }}
          />
        </Box>
      ),
    },
    {
      field: 'team',
      headerName: 'team',
      sortable: false,
      flex: 2,
      minWidth: 200,
      renderCell: ({
        row: {
          logo,
          name,
        },
      }) => (
        <Box sx={{
          gap: 4,
          display: 'flex',
          alignItems: 'center',
        }}
        >
          <Box>
            <Avatar
              src={logo}
              alt={name}
            />
          </Box>
          <Box>
            <Typography variant="bodyLargeMedium">
              {name}
            </Typography>
          </Box>
        </Box>
      ),
    },
    {
      field: 'points',
      headerName: 'points',
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row: { points } }) => (
        <TypographyWithIcon
          label={points ?? 0}
          icon={(
            <SparklingIcon
              width={typographyWithIconStyle.iconProps.width}
              height={typographyWithIconStyle.iconProps.height}
            />
          )}
          {...typographyWithIconStyle}
        />
      ),
    },
    {
      field: 'numberOfSolves',
      headerName: 'numberOfSolves',
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row: { numberOfSolves } }) => (
        <TypographyWithIcon
          label={numberOfSolves || 0}
          icon={(
            <FlagIcon
              width={typographyWithIconStyle.iconProps.width}
              height={typographyWithIconStyle.iconProps.height}
            />
          )}
          {...typographyWithIconStyle}
        />
      ),
    },
    {
      field: 'firstBloods',
      headerName: 'firstBloods',
      sortable: true,
      flex: 1,
      minWidth: 150,
      renderCell: ({ row: { firstBloods } }) => (
        <TypographyWithIcon
          label={firstBloods || 0}
          icon={(
            <BloodDropIcon
              width={typographyWithIconStyle.iconProps.width}
              height={typographyWithIconStyle.iconProps.height}
            />
          )}
          {...typographyWithIconStyle}
        />
      ),
    },
  ];
};

export default useTableColumns;
