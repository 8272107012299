const PERMISSIONS = {
  // User permissions
  userViewAll: 'userViewAll',
  userViewOwn: 'userViewOwn',
  userUpdateOwn: 'userUpdateOwn',
  userViewOne: 'userViewOne',
  userCreate: 'userCreate',
  userUpdate: 'userUpdate',
  userDelete: 'userDelete',
  userStatus: 'userStatus',
  userInvite: 'userInvite',
  userViewLatestActivity: 'userViewLatestActivity',
  userUpdateOthers: 'usersUpdateOthers',
  userViewOthers: 'usersViewOthers',
  userViewOthersEmails: 'userViewOthersEmails',
  // Country permissions
  countryCreate: 'countryCreate',
  countryUpdate: 'countryUpdate',
  countryDelete: 'countryDelete',

  // Role permissions
  roleViewAll: 'roleViewAll',
  roleViewOne: 'roleViewOne',
  roleCreate: 'roleCreate',
  roleUpdate: 'roleUpdate',
  roleDelete: 'roleDelete',
  roleAssignToUser: 'roleAssignToUser',

  // Permission permissions
  permissionViewAll: 'permissionViewAll',
  permissionViewOne: 'permissionViewOne',
  permissionAssignToRole: 'permissionAssignToRole',

  // Plan permissions
  planViewAll: 'planViewAll',
  planViewOne: 'planViewOne',
  planOnlyViewPublished: 'planOnlyViewPublished',
  planCreate: 'planCreate',
  planUpdate: 'planUpdate',
  planSendForApproval: 'planSendForApproval',
  planPublish: 'planPublish',
  planDraft: 'PlanDraft',
  planUpdatePending: 'planUpdatePending',
  planViewTotalProfits: 'planViewTotalProfits',
  planViewCurrentSubs: 'planViewCurrentSubs',
  planSubscribe: 'planSubscribe',
  planViewUnPublished: 'planViewUnPublished',

  // Labs permissions
  labViewAll: 'labViewAll',
  labViewOne: 'labViewOne',
  labCreate: 'labCreate',
  labUpdate: 'labUpdate',

  // Field-specific permissions
  labViewHidden: 'labViewHidden',
  labViewNumberOfSolvedChallenges: 'labViewNumberOfSolvedChallenges',

  // Challenges permissions
  challengesCreate: 'challengesCreate',
  challengesEdit: 'challengesEdit',
  challengesViewOne: 'challengesViewOne',
  challengesViewAll: 'challengesViewAll',
  challengesSendForPending: 'challengesSendForPending',
  challengesDraft: 'challengesDraft',
  challengesPublish: 'challengesPublish',
  challengesRetire: 'challengesRetire',
  challengesArchive: 'challengesArchive',
  challengesToggleHiddenFlag: 'challengesToggleHiddenFlag',
  challengesViewHidden: 'challengesViewHidden',
  challengesAssignPlan: 'challengesAssignPlan',
  challengesReassignPlan: 'challengesReassignPlan',
  challengesSubmitFlag: 'challengesSubmitFlag',
  challengesViewUnpublished: 'challengesViewUnpublished',
  challengesFullAccess: 'challengesFullAccess',
  challengesStartUnlimitedInstances: 'challengesUnlimitedInstances',
  // Field-specific permissions
  challengesViewPlans: 'challengesViewPlans',
  challengesViewVisibleField: 'challengesViewVisibleField',
  challengesViewCorrectFlagField: 'challengesViewCorrectFlagField',
  challengesViewFiles: 'challengesViewFiles',
  challengesViewCreatedBy: 'challengesViewCreatedBy',
  challengesViewAssigned: 'challengesViewAssigned',

  // Promo Code permissions
  promoCodeCreate: 'promoCodeCreate',
  promoCodeUpdate: 'promoCodeUpdate',
  promoCodeViewOne: 'promoCodeViewOne',
  promoCodeViewAll: 'promoCodeViewAll',
  promoCodePublish: 'promoCodePublish',
  promoCodeUpdateExpiredOrPublished: 'promoCodeUpdateExpiredOrPublished',
  promoCodeApply: 'promoCodeApply',
  // Field-specific permissions
  promoCodeViewTotalProfitsField: 'promoCodeViewTotalProfitsField',
  promoCodeViewCodeUsagesField: 'promoCodeViewCodeUsages',
  promoCodeViewRemainingUsagesField: 'promoCodeViewRemainingUsages',
  promoCodeViewDurationField: 'promoCodeViewDuration',
  promoCodeViewIsActive: 'promoCodeViewIsActive',
  promoCodeViewIsUnlimited: 'promoCodeViewIsUnlimited',
  promoCodeViewCreatedBy: 'promoCodeViewCreatedBy',
  promoCodeViewCreatedAt: 'promoCodeViewCreatedAt',
  promoCodeViewStatus: 'promoCodeViewStatus',
  promoCodeViewSendAnnouncement: 'promoCodeViewSendAnnouncement',
  promoCodeViewStartDate: 'promoCodeViewStartDate',
  promoCodeViewEndDate: 'promoCodeViewEndDate',
  promoCodeViewId: 'promoCodeViewId',
  promoCodeViewMaxUses: 'promoCodeViewMaxUses',

  // transactions permissions
  transactionViewOne: 'transactionViewOne',
  transactionViewAll: 'transactionViewAll',
  transactionGenerateInvoicePdf: 'transactionGenerateInvoicePdf',

  // Announcements permissions
  announcementCreate: 'announcementCreate',
  announcementUpdate: 'announcementUpdate',
  announcementViewOne: 'announcementViewOne',
  announcementViewAll: 'announcementViewAll',
  announcementDelete: 'announcementDelete',
  announcementPublish: 'announcementPublish',
  announcementExpire: 'announcementExpire',
  announcementUpdateExpiredOrPublished: 'announcementUpdateExpiredOrPublished',
  canViewUnpublishedAnnouncements: 'canViewUnpublishedAnnouncements',
  // Field-specific permissions
  announcementViewStatus: 'announcementViewStatus',
  announcementViewCreatedBy: 'announcementViewCreatedBy',

  // Machines permissions
  machineCreate: 'machineCreate',
  machineUpdate: 'machineUpdate',
  machineViewOne: 'machineViewOne',
  machinePublish: 'machinePublish',
  machineArchive: 'machineArchive',
  machineRetire: 'machineRetire',
  machineDelete: 'machineDelete',
  machinesRunInstance: 'machinesRunInstance',
  machinesTerminateInstance: 'machinesTerminateInstance',
  machinesReset: 'machinesReset',
  machineViewCreatedBy: 'machineViewCreatedBy',
  machineViewAll: 'machineViewAll',
  machinesViewUnpublishedMachines: 'machinesViewUnpublishedMachines',
  machineTypes: 'machineTypes',
  machineTypesViewAll: 'machineTypesViewAll',
  machineUnlimitedResets: 'machineUnlimitedResets',
  machineViewRootFlag: 'machineViewRootFlag',
  machineViewUserFlag: 'machineViewUserFlag',
  machineViewTemplateId: 'machineViewTemplateId',

  // Events permissions
  eventCreate: 'eventCreate',
  eventInvitationCreate: 'eventInvitationCreate',
  eventInvitationView: 'eventInvitationView',
  eventInvitationDelete: 'eventInvitationDelete',
  eventUpdate: 'eventUpdate',
  eventViewAll: 'eventViewAll',
  eventViewOne: 'eventViewOne',
  eventDelete: 'eventDelete',
  teamCreate: 'teamCreate',
  teamJoin: 'teamJoin',
  teamUpdate: 'teamUpdate',
  teamViewAll: 'teamViewAll',
  teamViewOne: 'teamViewOne',
  teamViewOneByName: 'teamViewOneByName',
  memberRemove: 'memberRemove',
  memberWithdraw: 'memberWithdraw',
  memberSetAsCaptain: 'memberSetAsCaptain',
  memberMoveToTeam: 'memberMoveToTeam',
  eventGetAll: 'eventGetAll',
  eventViewInvitations: 'eventViewInvitations',
  eventViewType: 'eventViewType',
  teamToggleBanning: 'teamToggleBanning',
};

export default PERMISSIONS;
