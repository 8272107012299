import { useParams } from 'react-router-dom';
import { getEventDetails } from 'services';
import { useCustomQuery } from 'reactQuery';
import { transformEventDetails } from 'util/transformers/';

const useEventDetails = (queryProps = {}) => {
  const { options, ...rest } = queryProps;

  const { eventName } = useParams();

  const query = useCustomQuery({
    queryKey: [`view-event-${eventName}`],
    queryFn: () => getEventDetails(eventName),
    options: {
      select: transformEventDetails,
      ...options,
    },
    ...rest,
  });

  return query;
};

export default useEventDetails;
