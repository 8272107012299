import {
  DashboardIcon,
  HomeIcon,
  UsersIcon,
  PlansIcon,
  LabsIcon,
  PromoCodeIcon,
  LeaderBoardIcon,
  AnnouncementsIcon,
  MachinesIcon,
  CalendarIcon,
  RouterIcon,
} from 'assets/icons';
import { authRoles, PATHS } from 'routes';

const {
  admin,
  technicalAdmin,
  businessAdmin,
  accountManager,
  labManager,
  player,
} = authRoles;
// Example
// const menuItem = {
//   id: 'util-typography',
//   title: 'Typography',
//   type: 'item',
//   url: '/utils/util-typography',
//   icon: icons.IconTypography,
//   breadcrumbs: false,
//   children: [
//     menuItem1,
//     menuItem2,
//     ...
//   ],
// };

const dashboard = {
  id: 'dashboard',
  title: 'common.dashboard',
  type: 'item',
  path: PATHS.root,
  icon: DashboardIcon,
};
const home = {
  id: 'home',
  title: 'common.home',
  type: 'item',
  path: PATHS.root,
  icon: HomeIcon,
};
const businessTeam = {
  id: 'businessTeam',
  title: 'common.businessTeam',
  type: 'item',
  path: `/${PATHS.users}/${PATHS.businessTeam}`,
};
const technicalTeam = {
  id: 'technicalTeam',
  title: 'common.technicalTeam',
  type: 'item',
  path: `/${PATHS.users}/${PATHS.technicalTeam}`,
};
const players = {
  id: 'players',
  title: 'common.players',
  type: 'item',
  path: `/${PATHS.users}/${PATHS.players}`,
};
const users = {
  id: 'users',
  title: 'common.users',
  type: 'group',
  path: `/${PATHS.users}`,
  icon: UsersIcon,
};
const technicalAdminUsers = {
  ...users,
  children: [
    technicalTeam,
    players,
  ],
};
const businessAdminUsers = {
  ...users,
  children: [
    businessTeam,
  ],
};
const adminUsers = {
  ...users,
  children: [
    businessTeam,
    technicalTeam,
    players,
  ],
};
const plans = {
  id: 'plans',
  title: 'common.plans',
  type: 'item',
  path: `/${PATHS.plans}`,
  icon: PlansIcon,
};
const labs = {
  id: 'labs',
  title: 'common.labs',
  type: 'item',
  path: `/${PATHS.labs}`,
  icon: LabsIcon,
};
const trainingLabs = {
  id: 'trainingLabs',
  title: 'labs.trainingLabs',
  type: 'item',
  path: `/${PATHS.labs}/${PATHS.trainingLabs}`,
};
const competitiveLabs = {
  id: 'competitiveLabs',
  title: 'labs.competitiveLabs',
  type: 'item',
  path: `/${PATHS.labs}/${PATHS.competitiveLabs}`,
};
const machines = {
  id: 'machines',
  title: 'pageTitles.machines',
  type: 'item',
  path: `/${PATHS.machines}`,
  icon: MachinesIcon,
};
const events = {
  id: 'events',
  title: 'pageTitles.events',
  type: 'item',
  path: `/${PATHS.events}`,
  icon: CalendarIcon,
};
const instances = {
  id: 'instances',
  title: 'pageTitles.instances',
  type: 'item',
  path: `/${PATHS.instances}`,
  icon: RouterIcon,
};
const playerLabs = {
  id: 'playerLabs',
  title: 'common.labs',
  type: 'group',
  path: `/${PATHS.labs}`,
  icon: LabsIcon,
  children: [
    trainingLabs,
    competitiveLabs,
    machines,
  ],
};
const promoCode = {
  id: 'promoCode',
  title: 'common.promoCodes',
  type: 'item',
  path: `/${PATHS.promoCodes}`,
  icon: PromoCodeIcon,
};
const leaderBoard = {
  id: 'leaderBoard',
  title: 'pageTitles.leaderBoard',
  type: 'item',
  path: `/${PATHS.leaderBoard}`,
  icon: LeaderBoardIcon,
};
const announcements = {
  id: 'announcements',
  title: 'pageTitles.announcements',
  type: 'item',
  path: `/${PATHS.announcements}`,
  icon: AnnouncementsIcon,
};

const drawerMenuItems = {
  [admin]: [
    dashboard,
    labs,
    machines,
    events,
    instances,
    leaderBoard,
    adminUsers,
    plans,
    promoCode,
    announcements,
  ],
  [businessAdmin]: [
    dashboard,
    businessAdminUsers,
    plans,
    labs,
    leaderBoard,
    promoCode,
    announcements,
  ],
  [technicalAdmin]: [
    dashboard,
    technicalAdminUsers,
    labs,
    events,
    instances,
    leaderBoard,
    machines,
  ],
  [accountManager]: [
    dashboard,
    plans,
    labs,
    leaderBoard,
    promoCode,
    announcements,
  ],
  [labManager]: [
    dashboard,
    labs,
    events,
    leaderBoard,
    machines,
  ],
  [player]: [
    home,
    playerLabs,
    events,
    leaderBoard,
  ],
};

export default drawerMenuItems;
