import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

const toggleEventLeaderboardFreeze = (eventName) => api(
  END_POINTS.toggleLeaderBoardFreeze(eventName),
  {
    method: METHODS.PATCH,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default toggleEventLeaderboardFreeze;
