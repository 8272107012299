import {
  Grid,
  Typography,
} from '@mui/material';
import {
  TeamIcon,
  FilledEncryptedPasswordIcon,
} from 'assets/icons';
import { CopyToClipboardButton, InfoCard } from 'components/molecules';
import { useAdminTeamDetails, useEventDetails, usePlayerTeamDetails } from 'reactQuery/queries';
import { useAuth, useLocale } from 'util/hooks';

const iconSize = {
  width: '45', height: '45',
};

const EventTeamInfo = () => {
  const { t } = useLocale();
  const { isManager, isPlayer } = useAuth();

  const {
    data: eventDetails = {},
  } = useEventDetails();

  const {
    data: adminTeamDetails = {},
  } = useAdminTeamDetails({
    options: {
      enabled: isManager,
    },
  });

  const {
    data: playerTeamDetails = {},
  } = usePlayerTeamDetails({
    options: {
      enabled: isPlayer,
    },
  });

  const teamDetails = isManager
    ? adminTeamDetails
    : playerTeamDetails;

  const {
    teamMaxCapacity: max = 0,
    teamMinCapacity: min = 0,
  } = eventDetails || {};

  const {
    members = [],
    password = '',
    isCurrentUserCaptain = false,
  } = teamDetails || {};

  const isFixedTeamCapacity = max === min;
  const canSeePassword = isCurrentUserCaptain || isManager;

  const teamMemberContent = (
    <Typography variant="bodyStandardBold">
      {t('common.member', { count: members?.length })}
    </Typography>
  );

  const teamCapacityInfo = (
    <Typography variant="bodySmallRegular" color="text.secondary" sx={{ textWrap: 'nowrap' }}>
      {isFixedTeamCapacity
        ? `${t('common.required')} ${t('common.member', { count: min })}`
        : t('events.requiredMembers', { min, max })}
    </Typography>
  );

  const passwordContent = (
    <Typography variant="bodyStandardBold">
      ******
    </Typography>
  );

  const copyPassword = (
    <CopyToClipboardButton
      buttonLabel={t('common.copyPassword')}
      textToCopy={password}
      buttonProps={{ sx: { padding: 0 } }}
    />
  );

  return (
    <Grid container spacing={6} sx={{ flexDirection: { xs: 'column', md: 'row' } }}>
      <InfoCard
        size="small"
        cardSx={{ pr: 4 }}
        key="team-members"
        icon={<TeamIcon {...iconSize} />}
        title={t('common.teamMembers')}
        component={teamMemberContent}
        endComponent={teamCapacityInfo}
      />
      {canSeePassword
        && (
          <InfoCard
            size="small"
            cardSx={{ pr: 4 }}
            key="team-password"
            icon={<FilledEncryptedPasswordIcon {...iconSize} />}
            title={t('events.teams.password')}
            component={passwordContent}
            endComponent={copyPassword}
          />
        )}
    </Grid>
  );
};

export default EventTeamInfo;
