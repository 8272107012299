import { useParams } from 'react-router-dom';
import {
  Box,
  Chip,
  Grid,
  Link,
  Avatar,
  Typography,
} from '@mui/material';

import { useIsEvent, useLocale } from 'util/hooks';
import { InfoCard } from 'components/molecules';

import { capitalizeFirstLetter } from 'util/helpers';
import { getDifficultyByName } from 'assets/constants/difficulties';
import {
  FlagsIcon,
  BloodDropIcon,
  ChallengeDifficultyIcon,
} from 'assets/icons';
import { PATHS } from 'routes';
import { useEventChallengeSubmissions } from 'reactQuery/queries';

const ChallengeStats = (props) => {
  const { challengeDetails = {} } = props;

  const {
    difficulty = '',
    numberOfSolutions = 0,
    submissions: labChallengeSubmissions = [], // lab submissions
  } = challengeDetails;

  const { t } = useLocale();
  const { eventName } = useParams();
  const isEventChallenge = useIsEvent();

  const {
    data: {
      items: eventChallengeSubmissions = [],
    } = {},
  } = useEventChallengeSubmissions({
    queryKeyOptions: {
      pagination: {
        limit: 500,
      },
    },
  }); // team submissions

  const submissions = isEventChallenge ? eventChallengeSubmissions : labChallengeSubmissions;

  const firstBloodSubmission = submissions
    ?.find((submission) => submission.firstBlood);

  const {
    team = {},
    player = {},
    firstBlood: isFirstBlood = false,
  } = firstBloodSubmission || {};

  const firstBloodInfo = isEventChallenge
    ? {
      name: team?.name,
      avatar: team?.logo,
      profileLink: `/${PATHS.events}/${eventName}/teams/${team?.id}`,
    }
    : {
      name: player?.username,
      avatar: player?.avatar,
      profileLink: `/profile/${player?.username}`,
    };

  const {
    name = '',
    avatar = '',
    profileLink = {},
  } = firstBloodInfo || {};

  const renderFirstBlood = () => {
    if (!isFirstBlood) {
      return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography variant="bodyStandardMedium">
            {t('common.none')}
          </Typography>
        </Box>
      );
    }

    return (
      <Box
        component={Link}
        to={profileLink}
        sx={{ display: 'flex', alignItems: 'center' }}
      >
        <Avatar
          src={avatar}
          alt={capitalizeFirstLetter(name)}
          sx={{ mr: 3 }}
        />
        <Typography
          variant="bodyStandardMedium"
          color="text.primary"
        >
          {name}
        </Typography>
      </Box>
    );
  };

  const renderDifficulty = () => (
    <Chip
      size="small"
      color={difficulty || ''}
      label={t(getDifficultyByName(difficulty)?.localizationKey) || ''}
    />
  );

  const renderTotalCorrectFlags = () => (
    <Typography variant="bodyLargeBold">
      {numberOfSolutions}
    </Typography>
  );

  const stats = [{
    key: 'challenge-firstBlood',
    icon: <BloodDropIcon width="40" height="40" />,
    title: t('challenges.firstBlood'),
    component: renderFirstBlood(),
  },
  {
    key: 'challenge-difficulty',
    icon: <ChallengeDifficultyIcon width="40" height="40" />,
    title: t('common.difficulty'),
    component: renderDifficulty(),
  },
  {
    key: 'challenge-totalCorrectFlag',
    icon: <FlagsIcon width="40" height="40" />,
    title: t('challenges.totalCorrectFlag'),
    component: renderTotalCorrectFlags(),
  }];

  return (
    <Box>
      <Grid container gap={10}>
        {stats.map((stat) => <InfoCard {...stat} />)}
      </Grid>
    </Box>
  );
};

export default ChallengeStats;
