import { useMemo, useState } from 'react';
import * as Yup from 'yup';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Alert,
  Card,
  Typography,
  Box,
  Link,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { FormCheckbox, FormPasswordField, FormTextField } from 'components/form';
import { acceptInvitation } from 'services';
import { REGEX } from 'util/helpers';
import { PATHS } from 'routes';
import { useLocale, useFastForm, useRecaptcha } from 'util/hooks';

const AcceptInvitationForm = (props) => {
  const {
    userRole = '',
  } = props;

  const { executeRecaptcha } = useRecaptcha({ action: 'ACCEPT_INVITATION' });
  const navigate = useNavigate();
  const { token } = useParams();
  const { t } = useLocale();

  const [acceptInvitationError, setAcceptInvitationError] = useState('');

  const onSubmit = async (values) => {
    const payload = { ...values, token };
    try {
      const recaptchaToken = await executeRecaptcha();
      await acceptInvitation(payload, { recaptchaToken });
      navigate(`/${PATHS.login}`);
    } catch (error) {
      const { errors } = error;
      errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (err.property === 'token') {
          setAcceptInvitationError(err.message);
        }
      });
    }
  };

  const defaultValues = {
    username: '',
    password: '',
    agreement: false,
  };

  const validationSchema = useMemo(() => Yup.object({
    username: Yup
      .string()
      .matches(REGEX.alphaNumericUnderscore, t('username.validation'))
      .required(t('username.enter'))
      .max(35, t('username.maxLength'))
      .min(4, t('username.minLength')),
    password: Yup
      .string()
      .matches(REGEX.password, t('password.invalidPassword'))
      .required(t('password.enterPassword')),
    agreement: Yup
      .bool()
      .required(t('agreement.validation'))
      .oneOf([true], t('agreement.validation')),
  }), [t]);

  const {
    control,
    handleSubmit,
    setError,
    formState: { isSubmitting, isDirty, isValid },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const registrationForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        py: 10,
        px: {
          sm: 25,
          xs: 5,
        },
      }}
      >
        {acceptInvitationError
          && (
            <Alert
              sx={{ width: 1, mb: 5 }}
              severity="error"
            >
              {acceptInvitationError}
            </Alert>
          )}
        <Box>
          <Typography variant="bodyMediumRegular">
            {`${t('invitation.acceptAs')} ${t(`roles.${userRole}`).toLowerCase()}`}
          </Typography>
          <Typography variant="h5" sx={{ mt: 1 }}>
            {t('invitation.completeRegistration')}
          </Typography>
        </Box>
        <Box sx={{ my: 8 }}>
          <FormTextField
            name="username"
            control={control}
            label={t('common.username')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box sx={{ mb: 14 }}>
          <FormPasswordField
            name="password"
            control={control}
            autoComplete="new-password"
            label={t('common.password')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <FormCheckbox
          name="agreement"
          control={control}
          disabled={isSubmitting}
          label={(
            <div>
              <Typography variant="bodyStandardRegular">
                {t('agreement.confirm')}
              </Typography>
              <Link to={`/${PATHS.terms}`}>
                <Typography variant="bodyStandardBold">
                  {t('agreement.terms')}
                </Typography>
              </Link>
            </div>
          )}
        />
        <LoadingButton
          sx={{ mt: 6, mb: 10 }}
          type="submit"
          variant="contained"
          size="large"
          fullWidth
          loading={isSubmitting}
          disabled={!isDirty || isSubmitting || !isValid}
        >
          {t('common.register')}
        </LoadingButton>
      </Box>
    </form>
  );

  return (
    <Card>
      {registrationForm}
    </Card>
  );
};

export default AcceptInvitationForm;
