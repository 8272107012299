import END_POINTS from 'services/endPoints';
import api from '../api';

const getEventChallengeDetails = (eventName, challengeId) => api(
  END_POINTS.eventChallenge(eventName, challengeId),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventChallengeDetails;
