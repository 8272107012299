import {
  Avatar,
  Box,
  Stack,
  Typography,
} from '@mui/material';
import {
  BloodDropIcon,
  SparklingIcon,
} from 'assets/icons';
import { TypographyWithIcon } from 'components/molecules';
import { capitalizeFirstLetter } from 'util/helpers';
import { useDate, useLocale } from 'util/hooks';

const typographyWithIconStyle = {
  iconProps: {
    mx: 0,
    mr: 2,
    width: 20,
    height: 20,
  },
  labelProps: { variant: 'bodyLargeMedium' },
};

const useTableColumns = () => {
  const { t } = useLocale();
  const { format, fromNow } = useDate({
    clockMode: true,
    clockPeriod: 10000,
  });

  return [
    {
      field: 'team',
      headerName: t('common.team'),
      sortable: false,
      flex: 1,
      minWidth: 200,
      renderCell: ({ row }) => {
        const {
          team = {},
        } = row;

        const {
          logo,
          name,
        } = team;

        return (
          <Box sx={{
            gap: 4,
            display: 'flex',
            alignItems: 'center',
          }}
          >
            <Box>
              <Avatar
                src={logo}
                alt={name}
              />
            </Box>
            <Box>
              <Typography variant="bodyLargeMedium">
                {name}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'challenge',
      headerName: t('common.challenge'),
      flex: 0.75,
      minWidth: 150,
      renderCell: ({ row }) => {
        const {
          challenge = {},
          firstBlood,
        } = row;
        const {
          title = '',
          category = {},
        } = challenge;

        return (
          <Stack direction="row" gap={2}>
            <Stack>
              <Box>
                <Typography variant="bodyLargeMedium">
                  {title}
                </Typography>
              </Box>
              <Box>
                <Typography variant="bodyStandardMedium" color="text.secondary">
                  {capitalizeFirstLetter(category)}
                </Typography>
              </Box>
            </Stack>
            {firstBlood && (
              <BloodDropIcon
                width="20"
                height="20"
              />
            )}
          </Stack>
        );
      },
    },
    {
      field: 'points',
      headerName: t('challenges.points'),
      flex: 0.5,
      minWidth: 150,
      renderCell: ({ row: { challenge } }) => (
        <TypographyWithIcon
          label={challenge?.points || 0}
          icon={(
            <SparklingIcon
              width="20"
              height="20"
            />
          )}
          {...typographyWithIconStyle}
        />
      ),
    },
    {
      field: 'date',
      headerName: t('common.date'),
      flex: 1,
      minWidth: 150,
      renderCell: ({ row: { date } }) => (
        <Stack>
          <Typography variant="bodyMediumMedium">
            {format(date, 'k:m:s')}
          </Typography>
          <Typography variant="bodyMediumMedium">
            {`(${fromNow(date)})`}
          </Typography>
        </Stack>
      ),
    },
  ];
};

export default useTableColumns;
