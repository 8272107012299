import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { LoadingButton } from '@mui/lab';
import { Box, Button } from '@mui/material';

import { PATHS } from 'routes';
import {
  FormTextField,
  FormRadioGroup,
  FormNumberField,
  FormDateTimePicker,
  FormSingleImageUpload,
} from 'components/form';
import { useEventDetails } from 'reactQuery/queries';
import {
  useDate,
  useLocale,
  useFastForm,
  useSnackbar,
} from 'util/hooks';
import { createEvent, editEvent } from 'services';

// now that doesn't re-render on state changes
const staticNow = new Date();

const EventDetailsFrom = (props) => {
  const {
    createMode = false,
    onClickCancel = () => { },
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();
  const { add, isBefore } = useDate();
  const {
    data: eventDetails = {},
    refetch: refetchEvent = () => { },
  } = useEventDetails({ options: { enabled: !createMode } });

  const {
    name: eventName = '',
    description = '',
    teamMinCapacity = 0,
    teamMaxCapacity = 0,
    registrationStartAt = '',
    registrationEndAt = '',
    eventStartAt = '',
    eventEndAt = '',
    type = 'public',
    image = '',
    sponsorImage = '',
  } = eventDetails;

  const handleCancel = () => {
    if (createMode) {
      navigate(`/${PATHS.events}`);
    } else {
      onClickCancel();
    }
  };

  const defaultValues = {
    name: eventName || '',
    description: description || '',
    teamMinCapacity: teamMinCapacity || 0,
    teamMaxCapacity: teamMaxCapacity || 0,
    registrationStartAt: registrationStartAt
      ? new Date(registrationStartAt)
      : staticNow,
    registrationEndAt: registrationEndAt
      ? new Date(registrationEndAt)
      : add(staticNow, { days: 7 }),
    eventStartAt: eventStartAt
      ? new Date(eventStartAt)
      : staticNow,
    eventEndAt: eventEndAt
      ? new Date(eventEndAt)
      : add(staticNow, { days: 7 }),
    type: type || 'public',
    image: image || '',
    sponsorImage: sponsorImage || '',
  };

  const disableRegistrationStartAt = !createMode
    && isBefore(defaultValues.registrationStartAt, staticNow);
  const disableEventStartAt = !createMode
    && isBefore(defaultValues.eventStartAt, staticNow);

  const validationSchema = Yup.object({
    name: Yup
      .string()
      .trim()
      .required(t('events.validation.enterName'))
      .min(1, t('events.validation.nameMinLength'))
      .max(100, t('events.validation.nameMaxLength')),
    description: Yup
      .string()
      .required(t('events.validation.enterDescription'))
      .min(1, t('events.validation.descMinLength'))
      .max(255, t('events.validation.descMaxLength')),
    teamMinCapacity: Yup
      .number()
      .required(t('events.validation.enterTeamMinCapacity'))
      .min(1, t('events.validation.teamMinCapacity'))
      .max(6, t('events.validation.teamMaxCapacity'))
      .transform((parsedValue, originalValue) => (originalValue === '' ? 0 : parsedValue)),
    teamMaxCapacity: Yup
      .number()
      .required(t('events.validation.enterTeamMaxCapacity'))
      .min(1, t('events.validation.teamMinCapacity'))
      .when('teamMinCapacity', (schemaTeamMinCapacity, schema) => schema.min(schemaTeamMinCapacity, t('events.validation.maxLarger')))
      .max(6, t('events.validation.teamMaxCapacity'))
      .transform((parsedValue, originalValue) => (originalValue === '' ? 0 : parsedValue)),
    registrationStartAt: Yup
      .date()
      .nullable()
      .min(disableRegistrationStartAt ? defaultValues.registrationStartAt : staticNow, t('events.validation.registrationStartAt'))
      .required(t('events.validation.enterStartDateAndTime')),
    registrationEndAt: Yup
      .date()
      .nullable()
      .when('registrationStartAt', (schemaRegistrationStartAt, schema) => schema.min(schemaRegistrationStartAt, t('events.validation.afterDate')))
      .required(t('events.validation.enterEndDateAndTime')),
    eventStartAt: Yup
      .date()
      .nullable()
      .min(disableEventStartAt ? defaultValues.eventStartAt : staticNow, t('events.validation.eventStartAt'))
      .when('registrationStartAt', (schemaRegistrationStartAt, schema) => schema.min(schemaRegistrationStartAt, t('events.validation.afterDate')))
      .required(t('events.validation.enterStartDateAndTime')),
    eventEndAt: Yup
      .date()
      .nullable()
      .when('eventStartAt', (schemaEventStartAt, schema) => schema.min(schemaEventStartAt, t('events.validation.afterDate')))
      .when('registrationEndAt', (schemaRegistrationEndAt, schema) => schema.min(schemaRegistrationEndAt, t('events.validation.afterDate')))
      .required(t('events.validation.enterEndDateAndTime')),
    type: Yup
      .string()
      .nullable(),
    image: Yup
      .string()
      .nullable()
      .required(t('events.validation.uploadImage')),
    sponsorImage: Yup
      .string()
      .nullable(),
  });

  const onSubmit = async (values) => {
    const dirtyPayload = {};
    Object.keys(dirtyFields)?.forEach((dirtyKey) => {
      dirtyPayload[dirtyKey] = values[dirtyKey];
    });

    try {
      let message = '';

      if (createMode) {
        const { name } = await createEvent(values);
        message = t('events.createSuccess');
        navigate(`/${PATHS.events}/${name}`);
      } else {
        const updatedEvent = await editEvent(eventDetails?.name, dirtyPayload);
        message = t('events.updateSuccess');
        navigate(`/${PATHS.events}/${updatedEvent?.name}`);

        // Update event
        refetchEvent(updatedEvent);
        // Turn off edit mode
        onClickCancel();
      }

      snack({
        message,
        severity: 'success',
      });
    } catch (error) {
      const { errors } = error;

      if (errors?.length) {
        errors?.forEach((err) => {
          setError(err.property, {
            type: 'api',
            message: err.message,
          });
        });
      } else if (!errors) { // Non-form errors
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  const {
    control,
    handleSubmit,
    setError,
    getValues,
    trigger,
    formState: {
      isSubmitting,
      isDirty,
      isValid,
      dirtyFields,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'grid',
        columnGap: 31,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          lg: 'repeat(2, 304px)',
          xl: 'repeat(2, 420px)',
        },
      }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 8,
        }}
        >
          <Box>
            <FormTextField
              name="name"
              control={control}
              label={t('common.name')}
              inputProps={{ maxLength: 100 }}
              disabled={isSubmitting}
              fullWidth
              showCharCount
            />
          </Box>
          <Box>
            <FormTextField
              name="description"
              control={control}
              label={t('common.description')}
              disabled={isSubmitting}
              fullWidth
              multiline
              minRows={7}
              maxRows={7}
              inputProps={{ maxLength: 255 }}
              showCharCount
            />
          </Box>
          <Box>
            <FormNumberField
              name="teamMinCapacity"
              control={control}
              label={t('events.teamMinCapacity')}
              disabled={isSubmitting || disableRegistrationStartAt}
              commaSeparated={false}
              fullWidth
            />
          </Box>
          <Box>
            <FormNumberField
              name="teamMaxCapacity"
              control={control}
              label={t('events.teamMaxCapacity')}
              disabled={isSubmitting || disableRegistrationStartAt}
              commaSeparated={false}
              fullWidth
            />
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'column',
          rowGap: 8,
        }}
        >
          <Box>
            <FormDateTimePicker
              name="registrationStartAt"
              control={control}
              label={t('events.registrationStartAt')}
              disabled={isSubmitting || disableRegistrationStartAt}
              fullWidth
              pickerProps={{
                disablePast: !disableRegistrationStartAt,
                minDateTime: disableRegistrationStartAt
                  ? defaultValues.registrationStartAt
                  : staticNow,
                onChange: () => trigger('registrationEndAt'),
              }}
            />
          </Box>
          <Box>
            <FormDateTimePicker
              name="registrationEndAt"
              control={control}
              label={t('events.registrationEndAt')}
              disabled={isSubmitting}
              fullWidth
              pickerProps={{
                disablePast: true,
                minDateTime: getValues('registrationStartAt'),
                onChange: () => {
                  if (!disableRegistrationStartAt) {
                    trigger('registrationStartAt');
                  }
                },
              }}
            />
          </Box>
          <Box>
            <FormDateTimePicker
              name="eventStartAt"
              control={control}
              label={t('events.eventStartAt')}
              disabled={isSubmitting || disableEventStartAt}
              fullWidth
              pickerProps={{
                disablePast: !disableEventStartAt,
                minDateTime: disableEventStartAt
                  ? defaultValues.eventStartAt
                  : staticNow,
                onChange: () => trigger('eventEndAt'),
              }}
            />
          </Box>
          <Box>
            <FormDateTimePicker
              name="eventEndAt"
              control={control}
              label={t('events.eventEndAt')}
              disabled={isSubmitting}
              fullWidth
              pickerProps={{
                disablePast: true,
                minDateTime: getValues('eventStartAt'),
                onChange: () => {
                  if (!disableEventStartAt) {
                    trigger('eventStartAt');
                  }
                },
              }}
            />
          </Box>
          <Box>
            <FormRadioGroup
              name="type"
              control={control}
              label={t('common.type')}
              options={[
                { label: t('common.public'), value: 'public' },
                { label: t('common.private'), value: 'private' },
              ]}
              disabled={isSubmitting || !createMode}
            />
          </Box>
        </Box>
        <Box mt={10}>
          <FormSingleImageUpload
            name="image"
            image={eventDetails?.image}
            label={t('common.image')}
            description={<li>{t('events.imageDescription')}</li>}
            disabled={isSubmitting}
            control={control}
            fullWidth
          />
        </Box>
        <Box mt={10}>
          <FormSingleImageUpload
            name="sponsorImage"
            image={eventDetails?.sponsorImage}
            label={t('common.sponsorImage')}
            description={<li>{t('events.imageDescription')}</li>}
            disabled={isSubmitting}
            control={control}
            fullWidth
          />
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 8,
        }}
      >
        <Box sx={{ mx: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isSubmitting}
            disabled={!isDirty || isSubmitting || !isValid}
          >
            {createMode ? t('common.submit') : t('common.saveChanges')}
          </LoadingButton>
        </Box>
        <Box>
          <Button
            onClick={handleCancel}
            disabled={isSubmitting}
            sx={{
              color: 'text.raspberry',
            }}
          >
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default EventDetailsFrom;
