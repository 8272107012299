import {
  Avatar,
  Box,
  Card,
  Link,
  Button,
  Typography,
} from '@mui/material';

import { useAuth, useLocale } from 'util/hooks';
import { StatusBadge } from 'components/molecules';
import { capitalizeFirstLetter } from 'util/helpers';
import { useAdminTeamDetails, useEventDetails, usePlayerTeamDetails } from 'reactQuery/queries';

const EventTeamOverview = () => {
  const { t } = useLocale();
  const { isManager, isPlayer } = useAuth();

  const { data: eventDetails = {} } = useEventDetails();

  const {
    data: adminTeamDetails = {},
  } = useAdminTeamDetails({
    options: {
      enabled: isManager,
    },
  });

  const {
    data: playerTeamDetails = {},
  } = usePlayerTeamDetails({
    options: {
      enabled: isPlayer,
    },
  });

  const { eventHasEnded } = eventDetails;

  const teamDetails = isManager
    ? adminTeamDetails
    : playerTeamDetails;

  const {
    logo = null,
    name: teamName = '',
    motto = '',
    status = '',
    isCurrentUserCaptain = false,
    id: teamId,
  } = teamDetails || {};

  const canEdit = isManager || (isCurrentUserCaptain && !eventHasEnded);
  const editPath = isManager ? 'edit' : `teams/${teamId}/edit`;

  return (
    <Card sx={{ py: 5, px: 8 }}>
      <Box sx={{
        display: 'flex',
        minWidth: 1,
        flexWrap: 'wrap',
        rowGap: 5,
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ mr: 4 }}>
              <Avatar
                src={String(logo)}
                alt={`${teamName}-team-avatar`}
                sx={{
                  width: { xs: 45, sm: 60 },
                  height: { xs: 45, sm: 60 },
                  fontWeight: 'bold',
                  fontSize: 23,
                }}
              >
                {capitalizeFirstLetter(teamName)}
              </Avatar>
            </Box>
            <Box sx={{ display: 'flex', flexDirection: 'column', wordBreak: 'break-word' }}>
              <Box sx={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                <Typography variant="bodyMediumMedium" color="text.lighterGray">
                  {teamName}
                </Typography>
                <Box sx={{ width: 14, height: 14, mx: 2 }}>
                  <StatusBadge status={status} />
                </Box>
                <Box>
                  <Typography variant="bodySmallMedium" color="text.secondary">
                    {t(`statuses.${status}`)}
                  </Typography>
                </Box>
              </Box>
              <Typography variant="bodyStandardRegular" color="text.secondary">
                {motto}
              </Typography>
            </Box>
            <Box />
          </Box>
        </Box>
        {canEdit && (
          <Box sx={{ ml: { xs: 15, sm: 18 } }}>
            <Button
              component={Link}
              size="small"
              color="secondary"
              variant="outlined"
              to={editPath}
            >
              {t('common.edit')}
            </Button>
          </Box>
        )}
      </Box>
    </Card>
  );
};

export default EventTeamOverview;
