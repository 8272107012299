import { PATHS } from 'routes';
import { DataList } from 'components/core';
import { useEventChallenges, usePlayerTeamDetails } from 'reactQuery/queries';
import { Stack, Typography } from '@mui/material';
import { useLocale } from 'util/hooks';
import useListColumns from './useListColumns';

const BannedTeamState = () => {
  const { t } = useLocale();
  return (
    <Stack height={344} alignItems="center" justifyContent="center">
      <Typography variant="bodyMediumMedium">{t('events.teams.bannedTitle')}</Typography>
      <Typography variant="bodyStandardRegular">{t('events.teams.bannedDescription')}</Typography>
    </Stack>
  );
};

const ChallengesList = () => {
  const columns = useListColumns();

  const {
    data: rows = [],
  } = useEventChallenges();

  return (
    <DataList
      rows={rows}
      columns={columns}
      withSearch={false}
      checkboxSelection={false}
      useLinkRow
      componentsProps={{
        row: { toPath: PATHS.challenges },
        noRowsOverlay: { type: 'challenges' },
      }}
      headerHeight={0}
      sx={{
        '& .MuiDataGrid-row': {
          pl: 8,
        },
      }}
    />
  );
};

const PlayerEventChallengeList = () => {
  const { data: teamDetails = {} } = usePlayerTeamDetails();
  const { isBanned = false } = teamDetails;

  return isBanned ? <BannedTeamState /> : <ChallengesList />;
};

export default PlayerEventChallengeList;
