import { useParams } from 'react-router-dom';
import { useCustomQuery } from 'reactQuery';
import { getEventInvitations } from 'services';

const useEventInvitations = () => {
  const { eventName } = useParams();

  const query = useCustomQuery({
    queryKey: [`event-invitations-${eventName}`],
    queryFn: (options) => getEventInvitations(eventName, options),
  });

  return { ...query };
};

export default useEventInvitations;
