import { useParams } from 'react-router-dom';

import { getEventChallenges } from 'services';
import { useCustomQuery } from 'reactQuery';

const useEventChallenges = (queryProps = {}) => {
  const { eventName } = useParams();

  const query = useCustomQuery({
    queryKey: [`event-challenges-${eventName}`],
    queryFn: () => getEventChallenges(eventName),
    ...queryProps,
  });

  return { ...query };
};

export default useEventChallenges;
