import END_POINTS from '../../endPoints';
import api from '../../api';

const getEventTeamActivity = (eventName, teamId) => api(
  END_POINTS.eventTeamActivity(eventName, teamId),
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventTeamActivity;
