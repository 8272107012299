import { useNavigate } from 'react-router-dom';

import { DataGrid } from 'components/core';
import { useEvents } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const EventTable = () => {
  const {
    data = {},
    isFetching = true,
  } = useEvents();

  const navigate = useNavigate();
  const columns = useTableColumns();

  const {
    items: { all: rows = [] } = {},
    meta = {},
  } = data || {};

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      getRowId={(row) => row.name}
      onRowClick={({ row }) => {
        const { name: eventName = '' } = row || {};
        navigate(eventName);
      }}
      componentsProps={{
        noRowsOverlay: { type: 'event' },
      }}
      sx={{
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
    />
  );
};

export default EventTable;
