import { useNavigate } from 'react-router-dom';

import { DataGrid } from 'components/core';
import { useEventTeams } from 'reactQuery/queries';
import useTableColumns from './useTableColumns';

const EventTeamTable = () => {
  const {
    data = {},
    isFetching = true,
    refetch: refetchEvenTeams,
  } = useEventTeams();

  const navigate = useNavigate();
  const columns = useTableColumns({ refetchEvenTeams });

  const {
    items: rows = [],
    meta = {},
  } = data || {};

  return (
    <DataGrid
      rows={rows}
      columns={columns}
      loading={isFetching}
      pagination={meta}
      onRowClick={({ row }) => {
        const { id: teamId = '' } = row || {};
        navigate(teamId);
      }}
      sx={{
        '& .MuiDataGrid-row:hover': {
          cursor: 'pointer',
        },
      }}
    />
  );
};

export default EventTeamTable;
