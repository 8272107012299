import END_POINTS from 'services/endPoints';
import api from '../../api';

const getEventLeaderboard = (eventName, options) => api(
  END_POINTS.eventLeaderboard(eventName),
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventLeaderboard;
