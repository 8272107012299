import { useParams } from 'react-router-dom';
import { playerGetTeamDetails } from 'services';
import { useCustomQuery } from 'reactQuery';
import { transformEventDetails } from 'util/transformers';
import { STATUS } from 'assets/constants/statuses';

const transformTeamDetails = (team) => {
  const event = transformEventDetails(team.event);
  const { teamMaxCapacity: max, teamMinCapacity: min } = event;
  const { members } = team;

  return {
    ...team,
    event,
    isQualifiedTeam: members?.length >= min && members?.length <= max,
    isBanned: team.status === STATUS.banned,
  };
};

const usePlayerTeamDetails = (queryProps = {}) => {
  const { options, ...rest } = queryProps;
  const { eventName } = useParams();

  const query = useCustomQuery({
    queryKey: [`${eventName}-player-view-team-details`],
    queryFn: () => playerGetTeamDetails(eventName),
    options: {
      select: transformTeamDetails,
      ...options,
    },
    ...rest,
  });

  return query;
};

export default usePlayerTeamDetails;
