import { getEventTeamActivity } from 'services';
import { useCustomQuery } from 'reactQuery';
import { useParams } from 'react-router-dom';

const transformTeamActivityDetails = (data) => (
  data?.map((activity) => ({
    ...activity,
    entityStatus: activity.challenge.status,
    entityVisibility: activity.challenge.visible,
  })));

const useEventTeamActivity = (queryProps = {}) => {
  const { eventName } = useParams();

  const {
    eventTeamId: teamId,
    queryKeyOptions = {},
    options = {},
    ...rest
  } = queryProps;

  const query = useCustomQuery({
    queryKey: [`event-${eventName}-team-${teamId}-activity`, queryKeyOptions],
    queryFn: () => getEventTeamActivity(eventName, teamId),
    options: {
      select: transformTeamActivityDetails,
      ...options,
    },
    ...rest,
  });

  return { ...query };
};

export default useEventTeamActivity;
