import { getEventTeams } from 'services';
import { useCustomQuery } from 'reactQuery';
import { useParams } from 'react-router-dom';

const useEventTeams = (queryProps = {}) => {
  const { eventName } = useParams();
  const {
    queryKeyOptions = {},
    ...rest
  } = queryProps;

  const query = useCustomQuery({
    queryKey: [`${eventName}-teams`, queryKeyOptions],
    queryFn: (options) => getEventTeams(eventName, options),
    ...rest,
  });

  return { ...query };
};

export default useEventTeams;
