import END_POINTS from '../../endPoints';
import api from '../../api';

const getEventTeams = (eventName, options) => api(
  END_POINTS.eventTeams(eventName),
  options,
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default getEventTeams;
