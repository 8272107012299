import { useMemo } from 'react';
import _ from 'lodash';
import { Box } from '@mui/material';

import { LabelWithBadge, Tabs } from 'components/molecules';
import { useLocale, useAuth, usePermission } from 'util/hooks';
import PERMISSIONS from 'assets/constants/permissions';

import { STATUS } from 'assets/constants/statuses';
import ChallengeList from '../ChallengeList/ChallengeList';
import PlayerChallengeList from '../ChallengeList/PlayerChallengeList';

const Challenges = (props) => {
  const { labDetails = {} } = props;
  const { challenges = [] } = labDetails || {};

  const {
    isPlayer,
  } = useAuth();

  const { t } = useLocale();

  const groupedChallenges = useMemo(() => _.groupBy(
    challenges,
    (challenge) => challenge.status,
  ), [challenges]);

  const showActiveTab = true;
  const showDraftTab = usePermission(PERMISSIONS.challengesDraft);
  const showPendingTab = !isPlayer;
  const showRetiredTab = true;
  const showArchivedTab = !isPlayer;

  const tabStatuses = [
    { key: STATUS.active, show: showActiveTab },
    { key: STATUS.draft, show: showDraftTab },
    { key: STATUS.pending, show: showPendingTab },
    { key: STATUS.retired, show: showRetiredTab },
    { key: STATUS.archived, show: showArchivedTab },
  ];

  const tabs = tabStatuses
    .filter((tabStatus) => tabStatus.show)
    .map(({ key }) => ({
      key,
      label: <LabelWithBadge
        label={t(`statuses.${key}`)}
        badgeContent={groupedChallenges[key]?.length || 0}
      />,
      component: isPlayer
        ? (
          <PlayerChallengeList
            challenges={groupedChallenges[key]}
            status={key}
          />
        )
        : (
          <ChallengeList
            challenges={groupedChallenges[key]}
            status={key}
          />
        ),
    }));

  return (
    <Box>
      <Box width={1}>
        <Tabs tabs={tabs} sx={{ maxWidth: { xs: 300, sm: 1 } }} />
      </Box>
    </Box>
  );
};

export default Challenges;
