import { useMemo } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import {
  Typography, Box, Stack, Button,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { InviteIcon } from 'assets/icons';
import { FormCheckbox, FormSelect, FormTextField } from 'components/form';
import { inviteUserService } from 'services';
import {
  getDependentsForRole,
} from 'assets/constants/userRoles';
import { useLocale, useFastForm, useSnackbar } from 'util/hooks';

const InviteUserForm = (props) => {
  const { user = {} } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const navigate = useNavigate();

  const roleOptions = getDependentsForRole(user?.role.id) // get deps
    ?.filter((dependent) => dependent.invitable) // filter invitable deps
    ?.map((option) => ({ ...option, label: t(option.localizationKey) })); // add labels

  const onSubmit = async (values) => {
    const {
      inviteAnother,
      ...userInfo
    } = values;

    const payload = {
      ...userInfo,
      role: { id: Number(userInfo.role) },
    };

    try {
      await inviteUserService(payload);
      if (inviteAnother) {
        reset({});
      } else {
        navigateBack();
      }

      snack({
        message: `${t('invitation.inviteMessage.invitation')}
         ${userInfo.email} ${t('invitation.inviteMessage.confirm')}`,
        severity: 'success',
      });
    } catch (error) {
      snack({
        message: (error.errors[0].message || t('common.somethingWrong')),
        severity: 'error',
      });
    }
  };

  const navigateBack = () => {
    navigate(-1);
  };

  const defaultValues = {
    email: '',
    role: roleOptions?.[0]?.id || '',
    inviteAnother: false,
  };

  const validationSchema = useMemo(() => Yup.object({
    email: Yup
      .string()
      .email(t('email.validation'))
      .required(t('invitation.enterEmail')),
    role: Yup
      .string()
      .required(t('invitation.enterRole')),
  }), [t]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { isSubmitting, isValid, isDirty },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const invitationForm = (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box sx={{
        display: 'grid',
        columnGap: 12,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 304px)',
          xl: 'repeat(2, 420px)',
        },
      }}
      >
        <Box sx={{ mt: 6 }}>
          <FormTextField
            name="email"
            type="email"
            control={control}
            label={t('common.email')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
        <Box sx={{ mt: 6 }}>
          <FormSelect
            name="role"
            control={control}
            options={roleOptions}
            label={t('common.role')}
            disabled={isSubmitting}
            fullWidth
          />
        </Box>
      </Box>
      <Box sx={{
        display: 'flex',
        flexDirection: { xs: 'column', sm: 'row' },
        justifyContent: { xs: 'start', sm: 'end' },
        flexWrap: 'wrap',
        mt: 8,
      }}
      >
        <Box>
          <FormCheckbox
            name="inviteAnother"
            control={control}
            label={t('invitation.inviteAnother')}
          />
        </Box>
        <Stack
          direction="row"
          gap={3}
        >
          <Button onClick={navigateBack} disabled={isSubmitting}>
            {t('common.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            disabled={!isValid || !isDirty || isSubmitting}
          >
            {t('invitation.sendInvitation')}
          </LoadingButton>
        </Stack>
      </Box>
    </form>
  );

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: {
        xs: 'column',
        md: 'row',
      },
      alignItems: {
        xs: 'center',
        md: 'start',
      },
    }}
    >
      <Box sx={{ width: 48, height: 44 }}>
        <InviteIcon width="48" height="44" />
      </Box>
      <Box sx={{
        width: {
          md: 1,
        },
        display: 'flex',
        flexDirection: 'column',
        mx: 5,
      }}
      >
        <Typography variant="bodyStandardMedium" sx={{ color: 'text.lightGray' }}>
          {t('invitation.inviteMembers')}
        </Typography>
        <Typography variant="bodySmallRegular" sx={{ color: 'text.secondary' }}>
          {t('invitation.inviteHeader')}
        </Typography>
        <Box>
          {invitationForm}
        </Box>
      </Box>
    </Box>
  );
};

export default InviteUserForm;
