import { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
  useLabDetails,
  useLabChallengeDetails,
  useEventChallengeDetails,
} from 'reactQuery/queries';
import {
  downloadWriteupFiles,
  downloadLabChallengeFiles,
  downloadEventChallengeFiles,
} from 'services';
import {
  useLocale,
  useIsEvent,
  useRecaptcha,
  useSnackbar,
} from 'util/hooks';

import CHALLENGE_FILES_TYPES from 'assets/constants/challengeFilesTypes';

const useDownloadChallengeFiles = (props) => {
  const {
    action = '',
    fileType = CHALLENGE_FILES_TYPES.challengeFiles,
  } = props;

  const { t } = useLocale();
  const snack = useSnackbar();
  const { eventName } = useParams();
  const { executeRecaptcha } = useRecaptcha({ action });

  const [isLoading, setIsLoading] = useState(false);

  const {
    data: labDetails = {},
  } = useLabDetails();
  const {
    data: labChallengeDetails = {},
  } = useLabChallengeDetails();
  const {
    data: eventChallengeDetails = {},
  } = useEventChallengeDetails();

  const isEventChallenge = useIsEvent();
  const challengeDetails = isEventChallenge ? eventChallengeDetails : labChallengeDetails;

  const { id: challengeId } = challengeDetails || {};
  const { id: labId } = labDetails || {};

  const handleDownload = async () => {
    setIsLoading(true);
    try {
      const recaptchaToken = await executeRecaptcha();
      if (fileType === CHALLENGE_FILES_TYPES.challengeFiles) {
        if (isEventChallenge) {
          await downloadEventChallengeFiles(eventName, challengeId, { recaptchaToken });
        } else {
          await downloadLabChallengeFiles(labId, challengeId, { recaptchaToken });
        }
      }

      if (fileType === CHALLENGE_FILES_TYPES.writeUpFiles) {
        await downloadWriteupFiles(labId, challengeId, { recaptchaToken });
      }
    } catch (error) {
      snack({
        message: error.message || error?.errors?.[0].message || t('common.errorMessage'),
        severity: 'error',
      });
    }
    setIsLoading(false);
  };

  return {
    isLoading,
    handleDownload,
  };
};

export default useDownloadChallengeFiles;
