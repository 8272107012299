// Authorization Roles

export const RoleId = {
  admin: 1,
  technicalAdmin: 2,
  businessAdmin: 3,
  accountManager: 4,
  labManager: 5,
  player: 6,
};

const authRoles = {
  admin: 'admin',
  technicalAdmin: 'technicalAdmin',
  businessAdmin: 'businessAdmin',
  accountManager: 'accountManager',
  labManager: 'labManager',
  player: 'player',
};

export const authGroups = {
  internal: [
    authRoles.admin,
    authRoles.technicalAdmin,
    authRoles.businessAdmin,
    authRoles.accountManager,
    authRoles.labManager,
  ],
  external: [
    authRoles.player,
  ],
};

export default authRoles;
