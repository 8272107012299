import { useMemo } from 'react';
import * as Yup from 'yup';
import { useNavigate } from 'react-router-dom';

import {
  Box,
  Button,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import {
  FormTags,
  FormTextField,
  FormNumberField,
  FormCheckboxArray,
  FormAutocompleteSelect,
} from 'components/form';

import { PATHS } from 'routes';
import { getDirtyObject, REGEX } from 'util/helpers';
import {
  useLocale,
  useSnackbar,
  useFastForm,
} from 'util/hooks';
import { createMachine, editMachine } from 'services';
import { usePlans, useMachineDetails, useMachineTypes } from 'reactQuery/queries';
import { STATUS } from 'assets/constants/statuses';

const MachineDetailsForm = (props) => {
  const {
    createMode = false,
    machineDetails = {},
  } = props;

  const snack = useSnackbar();
  const navigate = useNavigate();
  const { t, isAr } = useLocale();

  const {
    refetch = () => { },
  } = useMachineDetails();

  const {
    data: { items: allPlans = [] } = {},
  } = usePlans({
    queryKeyOptions: {
      filterOptions: { status: [STATUS.published] },
    },
  });

  const {
    data: machineTypes = [],
  } = useMachineTypes({
    options: {
      suspense: false, // prevent machine types from suspending whole component
    },
  });

  const plansOptions = useMemo(() => allPlans?.map((plan) => ({
    ...plan,
    label: isAr ? plan.nameAr : plan.nameEn,
  })), [isAr, allPlans]);

  const defaultValues = {
    name: machineDetails?.name || '',
    type: machineDetails?.type || null,
    templateId: machineDetails?.templateId || '',
    author: machineDetails?.author || '',
    userFlag: machineDetails?.userFlag || '',
    rootFlag: machineDetails?.rootFlag || '',
    userPoints: machineDetails?.userPoints || '',
    rootPoints: machineDetails?.rootPoints || '',
    tags: machineDetails?.tags || [],
    plans: machineDetails?.plans || [],
  };

  const validationSchema = Yup.object({
    name: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('machines.validation.enterName'))
      .required(t('machines.validation.enterName')),
    type: Yup
      .object()
      .nullable()
      .required(t('machines.validation.enterType')),
    templateId: Yup
      .string()
      .trim()
      .required(t('machines.validation.enterTemplateId')),
    author: Yup
      .string()
      .trim()
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('machines.validation.enterAuthor'))
      .required(t('machines.validation.enterAuthor')),
    userFlag: Yup
      .string()
      .trim()
      .min(1, t('challenges.validation.flagMinLength'))
      .max(250, t('challenges.validation.flagMaxLength'))
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('machines.validation.enterUserFlag'))
      .required(t('machines.validation.enterUserFlag')),
    rootFlag: Yup
      .string()
      .trim()
      .min(1, t('challenges.validation.flagMinLength'))
      .max(250, t('challenges.validation.flagMaxLength'))
      .matches(REGEX.alphaNumericSpaceSpecialCharacter, t('machines.validation.enterRootFlag'))
      .required(t('machines.validation.enterRootFlag')),
    userPoints: Yup
      .number()
      .required(t('machines.validation.enterUserPoints'))
      .typeError(t('machines.validation.enterNumbers'))
      .min(1, t('challenges.validation.pointsMinNumber'))
      .max(500, t('challenges.validation.pointsMaxNumber')),
    rootPoints: Yup
      .number()
      .required(t('machines.validation.enterRootPoints'))
      .typeError(t('machines.validation.enterNumbers'))
      .min(1, t('challenges.validation.pointsMinNumber'))
      .max(500, t('challenges.validation.pointsMaxNumber')),
    tags: Yup
      .array(),
    plans: Yup
      .array()
      .of(Yup
        .object()
        .shape({ id: Yup.number() }))
      .test({
        name: 'atLeastOneTrue',
        message: t('machines.validation.minPlans'),
        test: (arr) => arr.length > 0,
      }),
  });

  const {
    watch,
    control,
    setError,
    handleSubmit,
    formState: {
      errors,
      isDirty,
      isValid,
      isSubmitting,
    },
  } = useFastForm({
    defaultValues,
    validationSchema,
  });

  const onCancel = () => {
    if (createMode) {
      navigate(`/${PATHS.machines}`);
    } else {
      navigate(`/${PATHS.machines}/${machineDetails?.name}`);
    }
  };

  const onSubmit = async (values) => {
    const dirtyPayload = getDirtyObject(values, defaultValues);

    try {
      let message = t('machines.updateSuccess');

      if (createMode) {
        const { name } = await createMachine(values);
        message = t('machines.createSuccess');
        navigate(`/${PATHS.machines}/${name}`);
      } else {
        const { name: updatedMachineName } = await editMachine(dirtyPayload, machineDetails?.name);
        refetch();
        navigate(`/${PATHS.machines}/${updatedMachineName}`);
      }

      snack({
        message,
        severity: 'success',
      });
    } catch (error) {
      error.errors?.forEach((err) => {
        setError(err.property, {
          type: 'api',
          message: err.message,
        });
        if (!err.property || !watch(err.property)) {
          snack({
            severity: 'error',
            message: err.message || t('common.somethingWrong'),
          });
        }
      });

      if (!errors) { // Non-form errors
        snack({
          severity: 'error',
          message: error.message || t('common.somethingWrong'),
        });
      }
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Box mt={10}>
        <Box sx={{
          display: 'grid',
          rowGap: 10,
          columnGap: {
            lg: 15,
            xl: 31,
          },
          gridTemplateColumns: {
            xs: 'repeat(1, 1fr)',
            md: 'repeat(1, 420px)',
            lg: 'repeat(2, 315px)',
            xl: 'repeat(2, 390px)',
          },
        }}
        >
          <Box>
            <FormTextField
              name="name"
              control={control}
              label={t('common.name')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormAutocompleteSelect
              name="type"
              label={t('common.type')}
              control={control}
              optionKey="name"
              autoCompleteProps={{
                options: machineTypes,
              }}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="templateId"
              control={control}
              label={t('machines.templateId')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="author"
              control={control}
              label={t('machines.author')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="userFlag"
              control={control}
              label={t('machines.userFlag')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormTextField
              name="rootFlag"
              control={control}
              label={t('machines.rootFlag')}
              disabled={isSubmitting}
              fullWidth
            />
          </Box>
          <Box>
            <FormNumberField
              name="userPoints"
              control={control}
              label={t('machines.userPoints')}
              disabled={isSubmitting}
              commaSeparated={false}
              fullWidth
            />
          </Box>
          <Box>
            <FormNumberField
              name="rootPoints"
              control={control}
              label={t('machines.rootPoints')}
              disabled={isSubmitting}
              commaSeparated={false}
              fullWidth
            />
          </Box>
          <Box>
            <FormTags
              name="tags"
              control={control}
              label={t('common.tags')}
              setError={(message) => setError('tags', {
                type: 'manual',
                message,
              })}
            />
          </Box>
          <Box>
            <Typography
              variant="bodyStandardMedium"
              sx={{ color: 'text.lightGray' }}
            >
              {t('machines.plan')}
            </Typography>
            <Box sx={{ ml: 1, my: 4 }}>
              <FormCheckboxArray
                name="plans"
                control={control}
                options={plansOptions}
              />
              {errors?.plans && (
                <Typography
                  variant="bodySmallRegular"
                  color="error.main"
                >
                  {errors.plans?.message}
                </Typography>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'flex-end',
          mt: 8,
        }}
      >
        <Box sx={{ mx: 2 }}>
          <LoadingButton
            type="submit"
            variant="contained"
            size="medium"
            loading={isSubmitting}
            disabled={!isDirty || isSubmitting || !isValid}
          >
            {t('common.save')}
          </LoadingButton>
        </Box>
        <Box>
          <Button
            onClick={onCancel}
            disabled={isSubmitting}
            sx={{
              color: 'text.raspberry',
            }}
          >
            {t('common.cancel')}
          </Button>
        </Box>
      </Box>
    </form>
  );
};

export default MachineDetailsForm;
