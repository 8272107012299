import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Avatar from '@mui/material/Avatar';

const MachineAvatar = (props) => {
  const {
    machineType,
    ...rest
  } = props;

  const type = machineType.toLowerCase();
  const [src, setSrc] = useState('');
  const alt = `machine-${type}-icon`;

  useEffect(() => {
    const importIcon = async () => {
      const importedIcon = await import(`assets/images/machine-type-${type}.svg`);
      setSrc(importedIcon.default);
    };
    importIcon();
  }, [type]);

  return (
    <Avatar
      src={src}
      alt={alt}
      {...rest}
    />
  );
};

MachineAvatar.propTypes = {
  machineType: PropTypes.string,
};

MachineAvatar.defaultProps = {
  machineType: '',
};

export default MachineAvatar;
