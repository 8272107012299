import api from 'services/api';
import END_POINTS from 'services/endPoints';
import METHODS from 'services/methods';

const deleteSubmission = (eventName, challengeId, submissionId) => api(
  END_POINTS.deleteSubmission(eventName, challengeId, submissionId),
  {
    method: METHODS.DELETE,
  },
)
  .then((res) => Promise.resolve(res))
  .catch((error) => Promise.reject(error));

export default deleteSubmission;
